@import "../../../colors.scss";

.building-button {
   background: $backgroundGray;
   height: 120px;
   width: calc(100% - 20px);
   margin-right: 12px;
   margin-bottom: 20px;
   justify-content: center;
   align-items: center;
   display: flex;
   flex-flow: row nowrap;
   cursor: pointer;
   font-size: 0.8rem;
   color: $definiteGray;
   border-radius: 8px;
   font-weight: bold;
   span.anticon {
      display: block;
      font-size: 3rem;
      color: #999999;
      margin-bottom: 5px;
   }
   &:hover {
      background: #f0f0f0;
   }
}

@media (min-width: 600px) {
   .building-button {
      width: 200px;
      height: 200px;
      flex-flow: column nowrap;
   }
}
