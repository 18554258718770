@import "./../../../colors.scss";

.buttonField {
   border-radius: 18px;
   height: 30px;
   display: flex;
   align-items: center;
   &.hasIcon {
      padding: 5px 15px 5px 13px;
   }
   &.primary {
      background-color: $green;
      border-radius: 18px;
      border: none;
      color: #fff;
      &:hover,
      &:focus,
      &:disabled {
         color: #fff;
         background-color: $hoverGreen;
      }
   }
   &.light {
      background-color: #fff;
      border: none;
      border-radius: 18px;
      border: 2px solid $green;
      color: $green;
      &:hover,
      &:focus,
      &:disabled {
         color: $green;
      }
   }
   &.default {
      background-color: $black;
      border-radius: 18px;
      border: none;
      color: #fff;
      &:hover,
      &:focus,
      &:disabled {
         color: #fff;
         background-color: $hoverBlack;
      }
   }
   &.alternative {
      background-color: #fff;
      border: none;
      color: $black;
      border-radius: 18px;
      box-shadow: 0px 3px 6px #00000029;
      &:hover,
      &:focus,
      &:disabled {
         color: $hoverBlack;
      }
   }
   &.negative {
      background-color: $lineGray;
      border-radius: 18px;
      border: none;
      color: $black;
      &:hover,
      &:focus,
      &:disabled {
         color: $hoverBlack;
         background-color: $backgroundGray;
      }
   }
   &.link {
      color: $definiteGray;
      &:hover,
      &:focus,
      &:disabled {
         color: $green;
      }
   }
   &.state {
      border-radius: 18px;
      background-color: $lightGreen;
      border: none;
      color: $green;
      &:hover,
      &:focus,
      &:disabled {
         color: $green;
         background-color: $lightGreen;
      }
   }
   &.small {
      height: 32px !important;
   }
   &.middle {
      height: 34px !important;
   }
   &.large {
      height: 40px !important;
      border-radius: 20px;
   }
}
