@import "../../../colors.scss";
@import "../style.module.scss";

.createOrganization {
   @extend .onboarding;
   padding-top: 40px;
   @media screen and (max-width: 1600px) {
      padding-top: 20px;
   }
   .inputForm {
      width: 80%;
   }
}

.organization {
   width: 100%;
   display: flex;
   align-items: center;
   flex-direction: column;
   margin-top: 15px;
   &:first-child {
      margin-top: 0;
   }
   .organizationRow {
      @extend .organizationRow;
   }
   .yourTitle {
      position: relative;
      width: 100%;
      .applyAll {
         position: absolute;
         top: 25px;
         right: 0;
      }
   }
}

.organizationItem {
   @extend .organizationItem;
}

.createComponent {
   justify-content: center;
   align-items: center;
   display: flex;
   flex-direction: column;
   width: 450px;
   .title {
      margin-top: 20px;
      font-weight: bold;
      margin-bottom: 20px;
   }
   .inputForm {
      width: 100%;
   }
}
