@import "../../globals";

.lca-potential {
   display: inline-block;
   background-color: lighten($green, 50%);
   color: $green;
   padding: 5px 10px;
   border-radius: 10px;
   font-weight: bold;
   small {
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: block;
   }

   &.error {
      background-color: lighten(red, 45%);
      color: red;
   }

   &.hint {
      background-color: lighten(yellow, 40%);
      color: lighten(orange, 15%);
   }
}

.stack-bar-container {
   border: 1px solid #000;
   color: rgba(255, 255, 255, 0.7);

   .lca-potential-stack {
      display: inline-block;
      padding: 5px 10px;
      color: white;
      border-radius: 10px;
      font-weight: bold;
      small {
         font-size: 10px;
         text-transform: uppercase;
         letter-spacing: 1px;
         display: block;
      }

      &.error {
         background-color: lighten(red, 45%);
         color: red;
      }

      &.hint {
         background-color: lighten(yellow, 40%);
         color: lighten(orange, 15%);
      }
   }
}

.ant-skeleton-paragraph {
   li {
      width: 100% !important;
      height: 400px !important;
      transform: translateX(2.5%) !important;
   }
}
