@import "globals";
@import "colors";

@font-face {
   font-family: "Inter";
   font-style: normal;
   font-weight: 100;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-Thin.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-Thin.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: italic;
   font-weight: 100;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-ThinItalic.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-ThinItalic.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: normal;
   font-weight: 200;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-ExtraLight.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-ExtraLight.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: italic;
   font-weight: 200;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-ExtraLightItalic.woff2")
         format("woff2"),
      url("fonts/inter_web_latin/Inter-ExtraLightItalic.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: normal;
   font-weight: 300;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-Light.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-Light.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: italic;
   font-weight: 300;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-LightItalic.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-LightItalic.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: normal;
   font-weight: 400;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-Regular.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-Regular.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: italic;
   font-weight: 400;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-Italic.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-Italic.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: normal;
   font-weight: 500;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-Medium.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-Medium.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: italic;
   font-weight: 500;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-MediumItalic.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-MediumItalic.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: normal;
   font-weight: 600;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-SemiBold.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-SemiBold.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: italic;
   font-weight: 600;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-SemiBoldItalic.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-SemiBoldItalic.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: normal;
   font-weight: 700;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-Bold.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-Bold.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: italic;
   font-weight: 700;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-BoldItalic.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-BoldItalic.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: normal;
   font-weight: 800;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-ExtraBold.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-ExtraBold.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: italic;
   font-weight: 800;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-ExtraBoldItalic.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-ExtraBoldItalic.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: normal;
   font-weight: 900;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-Black.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-Black.woff") format("woff");
}

@font-face {
   font-family: "Inter";
   font-style: italic;
   font-weight: 900;
   font-display: swap;
   src: url("fonts/inter_web_latin/Inter-BlackItalic.woff2") format("woff2"),
      url("fonts/inter_web_latin/Inter-BlackItalic.woff") format("woff");
}

@import "~react-image-gallery/styles/scss/image-gallery.scss";

html,
body,
.ant-drawer .ant-drawer-close {
   font-family: "Inter", "system-ui";
   font-feature-settings: "ss01" on, "tnum" off, "kern" on, "calt" on;
   color: black;
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   p {
      color: black;
   }
}
/*.App >.ant-layout {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  .ant-layout-content {
    display: flex;
    flex-flow: column nowrap;
  }
}*/

.ant-btn,
.button {
   background-color: transparent;
   display: inline-block;
   height: 35px;
   padding: 5px 15px;
   font-size: 14px;
   border: 2px solid black;
   text-shadow: initial;
   box-shadow: initial;

   color: black;
   font-weight: bold;
   letter-spacing: -0.038em;
   &.ant-btn-round {
      border-radius: 100px;
   }
   &.ant-btn-lg {
      height: 50px;
      padding: 5px 20px;
   }
   &.ant-btn-primary {
      background-color: $green;
      border-color: $green;
      color: white;
      &.lined {
         background-color: transparent;
         color: $green;
         border-color: $green;
      }
   }
   &.collection-button {
      background-color: black;
      color: white;
      border-bottom-left-radius: 0;
   }
   &.yellow {
      background-color: #ffd900;
      border-color: #ffd900;
      color: black;
   }
   &:hover {
      transform: scale3d(1.02, 1.02, 1);
   }
   + .ant-btn,
   + .button {
      margin-left: 10px;
   }

   &.ant-btn-link,
   &.ant-btn-text {
      border: none;
      border-radius: initial;

      font-size: 14px;
      &:hover {
         transform: initial !important;
         text-decoration: underline;
      }
   }
   &.ant-btn-icon-only {
      width: 35px;
      padding: 0;
   }
}
.ant-popover {
   .ant-btn,
   .button {
      border: none;
      border-radius: initial;
      &:hover {
         transform: initial;
      }
   }
}

.ant-tag {
   border-radius: 100px;
   border-width: 2px;
   font-weight: bold;
   font-size: 14px;
}

.wrapper-style {
   background: #e2e2e2;
   min-height: 100vh;
   overflow-x: hidden;

   &.black {
      background: #000;
      border-color: #000;
      color: white;
   }
   &.blackborder {
      background: #fff;
      border-color: #000;
   }
   &.grey {
      background: $lightGray;
   }
}

button.coming {
   background-color: #444444;
   color: white;
   border: none;
   height: 32px;
   padding: 4px 16px;
   font-size: 14px;
   border-radius: 32px;
}

.content-wrap {
   min-height: 100%;
   //padding: 20px;
   //background-color: #f6f6f6;
}
.page-wrap {
   //min-height: 100%;
   //background-color: #f6f6f6;
}

.inner-wrap {
   padding: 0 20px;
}
.ant-page-header {
   padding: 20px 20px 0 20px;
   @media (min-width: 769px) {
      padding: 40px 20px 0 20px;
   }
   .ant-page-header-heading-title {
      display: none;
   }
   .ant-page-header-back-button {
      margin-bottom: 5px;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      position: relative;
      text-align: center;
      align-items: center;
      justify-content: center;
      border: 2px solid #e2e2e2 !important;

      &:hover {
         background-color: #e2e2e2 !important;
         color: black !important;
      }
      &:after {
         content: "<-";
         font-size: 24px;
         font-weight: bold;
         line-height: 35px;
      }
      span {
         display: none;
      }
   }

   .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
   .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
   .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
   .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
   .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
   .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
   .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
   .ant-menu-horizontal:not(.ant-menu-dark)
      > .ant-menu-submenu-selected::after {
      border-bottom: 2px solid $green;
      box-shadow: 0 0 5px $green;
   }
   .ant-page-header-heading-left {
      .ant-page-header-back {
         //margin-left:20px;
      }
      .ant-page-header-heading-title {
         font-size: 0.8rem;
      }
   }

   .ant-page-header-content {
      background-color: transparent;
      padding: 15px 0 15px 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      position: relative;
      @media (min-width: 600px) {
         flex-flow: column nowrap;
      }
      @media (min-width: 769px) {
         padding: 0px 0 50px 0;
      }

      .page-title {
         @media (min-width: 600px) {
            max-width: 80%;
         }
         h1 {
            font-weight: 700;
            margin: 0;
            font-size: 1.5rem;
            line-height: 1.5rem;
            letter-spacing: -0.038rem;
            margin-bottom: 10px;
            @media (min-width: 600px) {
               font-size: 30px;
               line-height: 2.5rem;
            }
            &.big-title {
               @media (min-width: 600px) {
                  font-size: 40px;
                  line-height: 3rem;
               }
            }
            span.noaccess {
               padding: 3px 5px;
               background-color: #eeeeee;
               border-radius: 5px;
               font-size: 10px;
               display: inline-block;
               margin-left: 10px;
            }
         }
         a.button {
            margin-top: 30px;
            margin-bottom: 30px;
         }
      }
      .manage-menu {
         display: flex;
         justify-content: flex-start;
         > * {
            margin-left: 10px;
            &:first-child {
               margin-left: 0;
            }
         }
      }
      @media (min-width: 600px) {
         flex-flow: row wrap;
         .manage-menu {
            justify-content: flex-end;
         }
      }
      @media (max-width: 600px) {
         position: static;
         .manage-menu {
            /* position: absolute;
        top: 20px;
        right:20px; */
            button.ant-btn-primary {
               position: fixed;
               bottom: 12px;
               right: 12px;
               z-index: 99;
               width: 48px;
               height: 48px;
               box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
               span {
                  display: none;
               }
               &:after {
                  width: 40%;
                  border-bottom: 2px solid white;
                  left: 30%;
                  content: " ";
                  position: absolute;
                  top: 48%;
               }
               &:before {
                  height: 40%;
                  right: auto;
                  bottom: auto;
                  width: 0;
                  opacity: 1;
                  border-left: 2px solid white;
                  top: 30%;
                  content: " ";
                  position: absolute;
                  left: 48%;
                  display: block;
               }
            }
         }
      }
   }
}

.page-image-header {
   position: relative;
   height: 200px;
   //width:100%;
   overflow: hidden;
   margin: 0 20px;
   img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
   }
}

.form-wrap {
   .form-wrap-inner {
      max-width: 800px;
      margin: 0 auto;
      padding: 20px;
   }
   .ant-page-header {
      padding: 20px 0;
      .ant-page-header-heading-left {
         .ant-page-header-back {
            margin-left: 0px;
         }
      }
      .ant-page-header-content {
         padding: 0px 0;
      }
   }
}
// page menu
.page-menu {
   background-color: white;
   position: relative;
   z-index: 1;
   border-bottom: 1px solid #000000;
   margin: 0 20px 20px 20px;
   a {
      padding: 10px 0 10px 0;
      margin-right: 20px;
      color: black;
      display: inline-block;
      font-weight: bold;
      &.disabled {
         color: #999999;
         font-weight: 400;
      }
   }
}
.ant-tabs-nav-list {
   margin: 0;
   .ant-tabs-ink-bar {
      background: $green;
   }
}
.ant-tabs-tab {
   font-weight: bold;
   &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
         color: $black;
      }
   }
   &:hover {
      color: $green;
   }
}

.page-content {
   padding-top: 20px;
   h4 {
      display: block;
      font-size: 0.7rem;
      flex-grow: 1;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #999;
      margin: 0 0 6px 0;
   }
}

.passport-id {
   display: inline-block;
   font-weight: bold;
   font-size: 0.6rem;
   padding: 2px 5px;
   background-color: #5c68d9;
   color: white;
   border-radius: 2px;
}

.grid {
   display: flex;
   flex-flow: row wrap;
   align-items: stretch;
   justify-content: space-between;
   width: calc(100% + 20px);
   margin-right: -20px;
   &::after {
      content: "";
      flex: auto;
   }
   .grid-item {
      width: calc(100% - 20px);
   }
   &.col-2 .grid-item {
      margin: 0 20px 20px 0;
      @media (min-width: 600px) {
         width: calc(50% - 20px);
      }
   }
   &.col-3 .grid-item {
      width: calc(33.333% - 20px);
      margin: 0 20px 20px 0;
      @media (min-width: 768px) {
         width: calc(50% - 20px);
      }
   }
   &.col-4 .grid-item {
      margin: 0 20px 20px 0;
      @media (min-width: 480px) {
         width: calc(50% - 20px);
      }
      @media (min-width: 1200px) {
         width: calc(25% - 20px);
      }
   }
}

.widget {
   padding: 10px 0;
   margin-bottom: 20px;
   &.number {
      display: flex;
      flex-flow: column nowrap;
      strong {
         display: block;
         font-size: 2rem;
         line-height: 2rem;
         font-weight: 700;
         letter-spacing: -0.038em;
         small {
            font-size: 1rem;
            line-height: 1rem;
         }
      }
   }
   &.data {
      display: flex;
      flex-flow: column nowrap;
      strong {
         display: block;
         font-size: 1rem;
         line-height: 1rem;
         font-weight: 500;
      }
   }

   & > span:first-child {
      display: block;
      flex-grow: 1;
      font-size: 14px;
      font-weight: 700;
      padding-bottom: 10px;
   }

   .no-data {
      height: 150px;
      margin-top: 10px;
      background-color: #f6f6f6;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
   }
}

.ant-tree,
.ant-tree-treenode,
.ant-tree-list,
.ant-tree-list-holder,
.ant-tree-list-holder-inner,
.ant-tree-treenode {
   background: transparent;
}

.light {
   opacity: 0.5;
   display: inline-block;
   margin-right: 3px;
}

.ant-layout.ant-layout-has-sider {
   @media (max-width: 768px) {
      flex-flow: column nowrap;
      .page-sidebar {
         width: 100% !important;
         max-width: 100% !important;
         display: block;
         //box-shadow: 0 5px 10px rgba(0,0,0,.1);
         flex: initial !important;
         .view-header {
            h2 {
               padding: 10px 20px 10px 20px;
            }
         }
         .ant-layout-sider-children {
            display: block;
         }
         ul.ant-menu {
            margin-right: 0;
            margin-left: 10px;
            width: auto;
            border-right: 0;
            display: flex;
            flex-flow: row nowrap;
            overflow-x: auto;
            overflow-y: hidden;

            li.ant-menu-item {
               border-radius: 0;
               border-left: none;
               position: relative;
               padding: 10px 10px;
               width: auto;
               height: auto;
               text-align: center;
               border: none;
               text-overflow: initial;
               overflow: visible;
               border-top-left-radius: 10px;
               border-top-right-radius: 10px;
               &:before {
                  right: 0;
                  border-top-left-radius: 10px;
                  border-bottom-right-radius: 0px;
                  bottom: 0;
                  width: 100%;
                  height: 5px;
                  top: auto;
               }
               &:after {
                  display: none;
               }
               span.anticon {
                  margin: 0;
                  font-size: 18px;
               }
               a {
                  //position: absolute;
                  //left: 0;
                  //text-indent: -9999px;
                  //right: 0;
                  //top: 0;
                  //bottom: 0;
               }

               &:hover {
                  border-left: none;
                  //border-bottom: 1px solid #dddddd;
               }
            }
            li.ant-menu-item.ant-menu-item-selected {
               border-left: none;
               //border-bottom: 1px solid $green;
            }

            .ant-menu-title-content {
               margin: 0;
            }
         }
      }
      .ant-layout-content {
         width: 100%;
      }
   }
   @media (min-width: 769px) {
      flex-flow: row nowrap;
   }
}

.ant-table-wrapper {
   overflow-x: auto;
}

.image-scroll {
   display: flex;
   flex-flow: row nowrap;
   overflow-x: auto;
   position: relative;
   width: calc(100%);
   margin-left: 20px;
   margin-bottom: 20px;
   &.single {
      align-items: stretch;
      width: calc(100% - 20px);
      figure,
      .ant-image {
         flex: 1;
         img {
            width: 100%;
            object-fit: cover;
         }
      }
   }
   figure,
   .ant-image {
      height: 300px;
      display: block;
      margin: 0 20px 0 0;
      width: auto !important;

      img {
         height: 100%;
         width: auto;
         display: block;
         border-radius: 20px;
      }
   }
}

.ant-checkbox-group {
   label {
      width: 200px;
      margin-bottom: 10px;
   }
}

.tag {
   border: 2px solid rgba(0, 0, 0, 0.2);
   border-radius: 100px;
   padding: 1px;
   display: flex;
   align-items: center;
   height: 30px;
   font-size: 12px;
   margin-right: 5px;
   span {
      display: inline-block;
      padding: 0px 10px;
      font-weight: bold;
   }
   i {
      border-radius: 100px;
      padding: 3px;
      display: inline-block;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-style: normal;
      height: 25px;
      min-width: 25px;
      text-align: center;
      color: $green;
      display: block;
   }
}

.d-flex {
   display: flex;
}

.d-flex-center {
   display: flex;
   justify-content: center;
}

.d-flex-between {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.d-flex-end {
   display: flex;
   justify-content: end;
}

.flex-align-start {
   align-items: flex-start;
}

.flex-align-end {
   align-items: flex-end;
}

.flex-align-center {
   align-items: center;
}

.validation-warning {
   color: red;
   margin-right: 20px;
   margin-top: 7px;
   position: absolute;
   height: 50px;
   span {
      margin-left: 6px;
   }
}

.ant-select-selector,
.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-form-item-has-error
   .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
   .ant-select-selector,
.ant-picker {
   border-radius: 5px !important;
   border: 2px solid $borderGray !important;
   &:hover {
      border: 2px solid $hoverGreen !important;
   }
   &:focus {
      box-shadow: 0 0 0 2px rgb(65 198 145 / 20%) !important;
   }
}

.ant-picker-focused {
   box-shadow: 0 0 0 2px rgb(65 198 145 / 20%) !important;
}

.mention .ant-input {
   border: none !important;
   &:hover {
      border: none !important;
   }
}

.ant-input-number {
   width: 100%;
}
.ant-input-number-focused,
.ant-input-affix-wrapper-focused,
.ant-select-focused,
.ant-select-selector {
   box-shadow: none !important;
}
.ant-input-affix-wrapper {
   padding: 0px 11px;
   .ant-input,
   .ant-select-selector {
      border: none !important;
      &:hover {
         border: none;
      }
   }
}
.ant-checkbox-wrapper.round {
   .ant-checkbox-inner {
      border-radius: 50px;
      border: 1px solid $green;
      &:hover {
         border-radius: 50px;
      }
   }
   .ant-checkbox-checked::after {
      border: none;
   }
   .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #27b47c;
   }
}

.antd-country-phone-input,
.no-arrow-number-input {
   input::-webkit-inner-spin-button,
   input::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
   }
}

.no-arrow-number-input {
   .ant-input-number-handler-wrap {
      display: none;
   }
}

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn:hover,
.ant-btn:focus {
   color: inherit;
   background-color: inherit;
   border-color: inherit;
}

.ant-popover.confirm-no-icon {
   .ant-popover-message-title {
      padding-left: 0;
   }

   .ant-popover-message {
      min-width: 110px;
      text-align: left;
   }

   .ant-popover-buttons {
      display: flex;
      justify-content: space-between;

      .ant-btn {
         background-color: $lineGray !important;
         border-radius: 23px;
         color: $black;
         width: auto;
         cursor: pointer;
         &:hover {
            background-color: $backgroundGray !important;
            color: $hoverBlack;
         }
      }
   }
}

.ant-collapse.no-padding {
   .ant-collapse-content-box {
      padding: 0;
   }
   .ant-collapse-arrow {
      opacity: 0.8;
   }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
   background-color: $backgroundGray;
}

.ant-table-wrapper.new-style {
   &.font-12 {
      .ant-table {
         font-size: 12px;
      }
   }
   &.font-13 {
      .ant-table {
         font-size: 13px;
      }
   }
   &.bold {
      .ant-table {
         font-weight: bold;
      }
   }
   &.header-font-14 {
      th {
         font-size: 14px;
      }
   }
   .ant-table-thead > tr > th {
      color: $definiteGray;
      background-color: transparent;
      padding: 15px 5px;

      &::before {
         display: none;
      }
   }
   .ant-table-tbody > tr > td {
      padding: 10px 3px;
   }
}

.responsivePageTitle {
   font-size: 40px;
   font-weight: bold;
   @media screen and (max-width: 1600px) {
      font-size: 30px;
   }
   @media screen and (max-width: 768px) {
      background-color: #fff;
      font-size: 18px;
      height: 58px;
      border-bottom: 1px solid $lineGray;
      padding-left: 67px;
      display: flex;
      align-items: center;
      width: 100%;
   }
}

.ant-menu-horizontal {
   .ant-menu-item-selected {
      color: $green !important;
      a {
         color: $green !important;
      }
   }
   .ant-menu-item-selected::after,
   .ant-menu-submenu-open::after {
      border-color: $green !important;
   }
   .ant-menu-submenu-active {
      color: $green !important;
   }
   .ant-menu-submenu-title:hover {
      color: $green !important;
   }
   .ant-menu-submenu-active::after {
      border-color: $green !important;
   }
}

.ant-menu-item-selected a {
   color: $green !important;
}
.ant-radio-group-large.ant-radio-group-solid {
   .ant-radio-button-wrapper {
      height: 36px;
      font-size: 14px;
      line-height: 36px;
      background: $lineGray;
      color: $black;
      &:first-child {
         border-top-left-radius: 50px;
         border-bottom-left-radius: 50px;
      }
      &:last-child {
         border-top-right-radius: 50px;
         border-bottom-right-radius: 50px;
      }

      border: none;
      &::before {
         display: none;
      }
   }
   .ant-radio-button-checked {
      background-color: $white;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 50px;
   }
   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: $black;
      border: none;
      background-color: $lineGray;
      &:first-child {
         border-top-left-radius: 50px;
         border-bottom-left-radius: 50px;
      }
      &:last-child {
         border-top-right-radius: 50px;
         border-bottom-right-radius: 50px;
      }
      &:focus-within {
         box-shadow: none;
      }
   }
}

.pageTitle {
   h1 {
      font-weight: 700;
      margin: 0;
      font-size: 1.5rem;
      line-height: 1.5rem;
      letter-spacing: -0.038rem;
      margin-bottom: 10px;
      @media (min-width: 600px) {
         font-size: 30px;
         line-height: 2.5rem;
      }
      &.big-title {
         @media (min-width: 600px) {
            font-size: 40px;
            line-height: 3rem;
         }
      }
   }
}

.align-center {
   text-align: center;
}

.ant-input-lg {
   height: 40px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
   background: $green;
}

.ant-form-item-label > label {
   color: $definiteGray;
}
