@import "../../../../colors.scss";

.building-item {
   width: calc(100% - 20px);
   height: auto;
   margin-right: 24px;
   margin-bottom: 20px;
   background-color: $white;
   border-radius: 8px;
   box-shadow: 0px 2px 5px $borderGray;
   padding-bottom: 9px;
   .activateLabeled {
      opacity: 0.7;
   }
   a {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      position: relative;
      .tags {
         position: absolute;
         z-index: 1;
         width: 100%;
         display: flex;
         padding: 8px 8px 0;
         justify-content: space-between;
         .tagItem {
            border-radius: 10px;
            padding: 1px 8px;
            font-size: 12px;
            font-weight: bold;
            margin-right: 0;
         }
         .shared {
            background: $borderGray;
            color: black;
         }
         .activate {
            background: $black;
            color: $white;
         }
         .plan {
            background-color: $blue;
            color: $white;
         }
      }
   }
   .ant-image {
      height: 120px;
      width: 120px;
      flex-shrink: 0;
      margin-right: 20px;
      overflow: hidden;
      border-radius: 8px;
      @media (max-width: 600px) {
         height: 80px;
         width: 80px;
      }
      img {
         height: 100%;
         width: 100%;
         object-fit: cover;
         transition: transform 0.1s ease-in-out;
      }
   }
   &:hover {
      .ant-image {
         box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
         img {
            transform: scale3d(1.05, 1.05, 1);
         }
      }
   }
   h5 {
      margin: 4px 0 5px 0;
      padding: 4px 10px 2px;
      font-size: 14px;
   }
   h5 + span {
      font-size: 0.7rem;
      line-height: 1.2rem;
      display: block;
   }
   .city-name {
      padding-left: 10px;
   }
}

@media (min-width: 600px) {
   .building-item {
      width: 200px;
      a {
         flex-flow: column nowrap;
         align-items: flex-start;
      }
      .ant-image {
         height: 140px;
         width: 200px;
         margin-right: 0;
         img {
            height: 140px;
            width: 200px;
            object-fit: cover;
         }
      }
   }
}
