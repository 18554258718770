@import "../../colors.scss";
.onboarding {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 50px 0;
   min-height: 100vh;
   margin: 0;
   .wrapper {
      border: 2px solid $borderGray;
      border-radius: 12px;
      padding-top: 50px;
      padding-bottom: 20px;
      width: 757px;
      height: auto;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media screen and (max-width: 1600px) {
         width: 600px;
         padding-top: 40px;
         padding-bottom: 40px;
      }
      .welcomeImage {
         display: flex;
         justify-content: center;
         .logo {
            width: 169px;
            @media screen and (max-width: 1600px) {
               width: 130px;
            }
         }
      }
   }
   .title {
      font-weight: bold;
      font-size: 19px;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 20px;
      @media screen and (max-width: 1600px) {
         padding-top: 20px;
         padding-bottom: 10px;
      }
   }
}

.bottomButtons {
   display: flex;
   justify-content: space-between;
   margin-top: 30px;
   margin-bottom: 10px;
   width: 100%;
}
.linkButton {
   color: $definiteGray;
   text-align: center;
}
.goBack {
   @extend .linkButton;
}

.organizationRow {
   display: flex;
   justify-content: space-between;
   margin-bottom: 5px;
   box-shadow: 0px 3px 6px #00000029;
   padding: 10px;
   border-radius: 12px;
   align-items: center;
   width: 100%;
}

.organizationItem {
   @media screen and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
   }
   .wrapperInfo {
      display: flex;
      align-items: flex-start;
      &.singleRow {
         align-items: center;
      }
      direction: ltr;
      .logo {
         padding: 5px;
         border: 2px solid #c9d0d5;
         border-radius: 12px;
         width: 50px;
         height: 50px;
         min-width: 50px;
         display: flex;
         justify-content: center;
         align-items: center;
         @media screen and (max-width: 1600px) {
            width: 35px;
            height: 35px;
            min-width: 35px;
         }
         &.nonLogo {
            background-color: $lineGray;
            .defaultLogo {
               width: 20px;
               height: 20px;
               border: 2px solid #000;
               border-radius: 50px;
               @media screen and (max-width: 1600px) {
                  width: 15px;
                  height: 15px;
               }
            }
         }
         img {
            width: 20px;
            height: 20px;
            object-fit: contain;
         }
      }
      .name {
         font-weight: bold;
         padding-left: 12px;
         font-size: 16px;
         color: $black;
         @media screen and (max-width: 1600px) {
            font-size: 14px;
         }
      }
      .companyInfo {
         display: flex;
         flex-direction: column;
         padding-top: 3px;
         line-height: 20px;
         @media screen and (max-width: 1600px) {
            line-height: 16px;
         }
         .type {
            padding-left: 12px;
            color: $definiteGray;
            font-size: 13px;
            @media screen and (max-width: 1600px) {
               font-size: 10px;
               font-weight: bold;
            }
         }
      }
   }
   .tagWrapper {
      display: flex;
      @media screen and (min-width: 769px) {
         padding-top: 8px;
      }
      @media screen and (max-width: 768px) {
         align-items: center;
      }
      .tag {
         font-size: 11px;
         padding: 0px 10px;
         min-width: auto;
         height: 25px;
      }
   }
}
