@import "../../colors";
// header
.menu-button {
   height: 57px !important;
   padding: 10px 25px !important;
   background-color: #fff !important;
   span {
      color: #000;
      font-size: 18px;
   }
   z-index: 1;
   @media screen and (min-width: 769px) {
      display: none !important;
   }
}
.mobile-header {
   height: 57px;
   background-color: #fff;
   // border-bottom: 1px solid $backgroundGray;
   position: absolute;
   width: 100%;
   @media screen and (min-width: 769px) {
      display: none;
   }
}
.header.site-layout-background {
   height: 100%;
   position: fixed;
   z-index: 4;
   background-color: white;
   bottom: 0;
   top: 0;
   left: 0;
   border-right: 1px solid $lineGray;
   transition: all 0.4s;
   overflow: hidden;
   padding: 20px 15px 0px 3px;
   width: 80px;
   @media screen and (max-width: 768px) {
      width: 100%;
      display: none;
      padding: 0;
      border-right: none;
      .ant-menu-item-selected {
         background-color: $backgroundGray;
         &::before {
            visibility: hidden;
         }
      }
      .menu-button {
         display: flex;
      }
      &.open {
         // width: 100%;
         display: block;
         padding-right: 15px;
      }
   }
   &:hover {
      @media screen and (min-width: 769px) {
         width: 250px;
         transition: all 0.4s;
      }
      ul.ant-menu-inline {
         .ant-menu-item-selected {
            background-color: $backgroundGray;

            &::before {
               visibility: hidden;
            }
         }
      }
      .app-commit-details,
      .footer-action-item .settings-btn,
      .footer-action-item .logout-btn {
         visibility: visible !important;
         transition: all 1s;
      }
   }

   ul.ant-menu-inline {
      border-right: none;
      li.ant-menu-item {
         font-weight: bold;
         color: $black;
         border-radius: 0 20px 20px 0;
         padding: 14px 14px 14px 28px !important;
         .ant-menu-title-content {
            display: flex;
            align-items: center;
            font-size: 14px;
         }
         &:hover {
            color: $black;
         }
         &:after {
            display: none;
         }
         img {
            width: 15px;
            margin-right: 13px;
         }
      }
      .ant-menu-item-selected {
         @media screen and (min-width: 769px) {
            background-color: $white;
            padding: 0;

            .ant-menu-title-content {
               z-index: 1;
            }
            &::before {
               background-color: #ececec;
               border-radius: 100%;
               width: 37px;
               height: 37px;
               content: "";
               position: absolute;
               z-index: 0;
               top: 2px;
               left: 17px;
            }
         }
      }
   }

   .logout-button {
      float: right;
      position: absolute;
      right: 16px;
      top: 5px;
   }
   .user-avatar-wrapper {
      margin-left: auto;
      height: auto;
      flex-shrink: 0;
      position: absolute;
      bottom: 20px;
      left: 33px;
      left: 0;
      width: 95%;
      padding-left: 23px;
      .footer-action-item {
         display: flex;
         align-items: center;
         line-height: initial;
         justify-content: space-between;
         .settings-btn,
         .logout-btn {
            @media screen and (min-width: 769px) {
               visibility: hidden;
            }
         }
      }
      .app-commit-details {
         padding: 7px 15px;
         font-size: 0.6rem;
         color: #999999;
         line-height: 14px;
         height: 42px;
         @media screen and (min-width: 769px) {
            visibility: hidden;
         }
         text-align: right;
      }
   }
}

.ant-popover-inner-content {
   button.ant-btn {
      padding: 7px 15px;
      text-align: left;
      width: 100%;
      display: block;
      margin: 0;
      height: auto;
      line-height: initial;
   }
}

// page layout

.main-menu-wrapper {
   display: inline-block;
   margin-left: 0;
}

main {
   background-color: $lightGray;
   &#main {
      @media screen and (min-width: 769px) {
         margin-left: 80px;
      }
   }
   .site-layout-background {
      background: #fff;
   }
   main {
      &.ant-layout-content {
         background: #fff;
      }
   }
}

.ant-layout-sider {
   background-color: $lightGray !important;
}

.ant-layout {
   background: #fff;
   position: relative;
   min-height: 100vh !important;
}

h1,
h2,
h3,
h4,
h5 {
   &.ant-typography {
      font-weight: bold;
      line-height: 1.3rem;
   }
}
