@import "../../../colors.scss";

.accessWrapper {
   padding: 40px 50px;
   @media screen and (max-width: 1600px) {
      padding: 30px 40px;
   }
   .pageHeader {
      .pageTitle {
         font-size: 40px;
         margin-bottom: 25px;
         font-weight: bold;
         @media screen and (max-width: 1600px) {
            font-size: 30px;
            margin-bottom: 20px;
         }
      }
   }

   .section {
      margin-bottom: 50px;
      .sectionTitle {
         font-size: 24px;
         font-weight: bold;
         margin-bottom: 15px;
         @media screen and (max-width: 1600px) {
            font-size: 17px;
         }
      }
      .username {
         padding-bottom: 8px;
      }
      .userTitle {
         margin-right: 15px;
      }
      .tag {
         background-color: $lightGreen;
         border-radius: 10px;
         padding: 5px 15px;
         font-weight: bold;
      }
      .organizationRow {
         margin-bottom: 15px;
         display: flex;
         align-items: center;
      }
      .pending {
         color: $definiteGray;
      }
   }
   .resend-invite-btn {
      span {
         color: $black;
      }
   }
   .userTypeSelect {
      width: 120px;
   }
}
