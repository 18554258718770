@import "../../../colors.scss";

.dashboard {
   padding: 30px 50px;
   background-color: $lightGray;
   @media screen and (max-width: 1600px) {
      padding: 20px 40px;
   }
   @media screen and (max-width: 768px) {
      padding: 0;
   }
   // .pageTitle {
   //    font-size: 40px;
   //    font-weight: bold;
   //    @media screen and (max-width: 1600px) {
   //       font-size: 30px;
   //    }
   //    @media screen and (max-width: 768px) {
   //       background-color: #fff;
   //       font-size: 18px;
   //       height: 58px;
   //       border-bottom: 1px solid $lineGray;
   //       padding-left: 70px;
   //       display: flex;
   //       align-items: center;
   //    }
   // }
   .content {
      @media screen and (max-width: 768px) {
         padding: 10px 25px;
      }
   }
   .block {
      box-shadow: 0px 3px 6px #00000029;
      padding: 15px 18px 20px;
      width: 100%;
      margin-bottom: 24px;
      @media screen and (max-width: 1600px) {
         padding: 15px 15px 20px;
      }
      border-radius: 12px;
      background-color: $white;
      .organizationItem {
         margin: 18px 0 5px;
      }
      .logo {
         width: 20px;
         height: 20px;
         margin-right: 10px;
         margin-top: 4px;
         @media screen and (max-width: 1600px) {
            width: 15px;
            height: 15px;
         }
      }
      .title {
         font-size: 18px;
         font-weight: bold;
         @media screen and (max-width: 1600px) {
            font-size: 16px;
         }
      }
      .view {
         font-size: 14px;
         padding-right: 0;
         @media screen and (max-width: 1600px) {
            font-size: 12px;
         }
      }

      &.profile {
         font-weight: bold;
         font-size: 13px;
         padding: 18px;
         min-height: 351px;
         .description {
            margin-top: 10px;
         }
         .progressBar {
            padding: 10px 0;
         }
         .stages {
            padding: 5px 0 15px;
            pointer-events: none;
         }
         .options {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 25px;
            div {
               align-items: flex-start;
               padding-right: 10px;
            }
            .icon {
               width: 17px;
               margin-right: 15px;
               margin-top: 4px;
            }
            button {
               min-width: 121px;
            }
         }
      }
   }
}
