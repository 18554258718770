.welcome-page {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-flow: row wrap;
   min-height: 100vh;
   padding: 20px 50px;
   .background {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;
      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   .welcome-box {
      z-index: 1;
      position: relative;
      .welcome-box-inner,
      .section {
         --width: 28.75rem;
         --min-width: 20rem;
         --padding: 35px 40px;
         --margin-bottom: 20px;
         --container-display: flex;
         --container-justify: center;
         --container-align: center;
         width: 28.75rem;
         min-width: 20rem;
         padding: 35px 40px;
         margin-bottom: 20px;
         container-display: flex;
         container-justify: center;
         container-align: center;
         height: auto;
         --container-height: auto !important;
         background-color: white;
         border-radius: 10px;
         border: none;
         input,
         .ant-form-item-control {
            width: 100%;
            max-width: 100%;
         }
         button {
            width: 100%;
            max-width: 100%;
            display: block;
            margin-bottom: 0px;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            touch-action: manipulation;
            cursor: pointer;
            user-select: none;
            background-image: none;
            line-height: 14px;
            color: white;
            height: auto;
            padding: 16px;
            letter-spacing: 0.75px;
            text-transform: uppercase;
            background-color: rgb(29, 165, 122);
            border-width: 0;
         }
      }
      > *:first-child {
         height: auto;
         --container-height: auto !important;
      }
   }
   .welcome-title {
      z-index: 1;
      position: relative;
      margin: 60px auto;
      text-align: center;
      max-width: 500px;
      h1 {
         color: white;
         font-weight: bold;
         font-size: calc(18px + 2vw);
         line-height: calc(20px + 2.2vw);
         margin: 20px;
      }
      @media (min-width: 1060px) {
         margin: 0;
         text-align: left;
         h1 {
            font-size: 50px;
            line-height: 55px;
         }
      }
   }
   .auth-container {
      z-index: 1;
   }
}
