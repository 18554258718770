@import "../../colors.scss";

.buildingSlider {
   background-color: $lightGray;
   border-right: 1px solid $backgroundGray;
   @media (min-width: 769px) {
      flex: 0 0 250px !important;
      max-width: 250px !important;
      min-width: 250px !important;
      width: 250px !important;
      margin-top: -2px;
   }
   @media (max-width: 768px) {
      flex: none !important;
      max-width: 100% !important;
      min-width: 100% !important;
      width: 100% !important;
      margin-top: 0;
   }
   .viewHeader {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 30px 10px;
      @media (max-width: 768px) {
         padding: 0;
      }
      .image {
         margin-right: 10px;
         width: 35px;
         height: 35px;
         display: block;
         object-fit: cover;
         border-radius: 5px;
         @media (min-width: 769px) {
            margin-left: 15px;
         }
      }
      .viewTitle {
         margin: 0;
         font-size: 14px;
         font-weight: 800;
         letter-spacing: -0.038em;
      }
      .subtitle {
         color: $definiteGray;
         font-size: 11px;
      }
   }

   .menu {
      border-right: none;
      background: none;
      margin-left: 15px;
      @media (max-width: 768px) {
         margin-left: 0;
      }
      .menuItem {
         padding: 0 0 0 20px;
         border-left: 0px solid transparent;
         border-top-left-radius: 100px;
         border-bottom-left-radius: 100px;
         line-height: 36px;
         position: relative;
         display: flex;
         align-items: center;
         color: $black;
         font-weight: bold;
         @media (min-width: 768px) {
            height: 35px;
         }
         @media (max-width: 768px) {
            padding: 0 15px !important;
            span {
               margin-left: 0 !important;
            }
         }
         &:hover,
         &:focus {
            color: $black;
         }
         a {
            color: $black;

            &:hover,
            &:focus {
               color: $black;
            }
         }
         img {
            height: 12px;
            @media (max-width: 768px) {
               display: none;
            }
         }
         &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 5px;
            content: " ";
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
         }
      }
   }
}
