.public-demand-page {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-flow: column wrap;
   min-height: 100vh;
   padding: 20px 50px;
   position: relative;
   .background {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      top: 0;
      height: 100%;
      .ant-image {
         height: 100% !important;
         img {
            width: 100%;
            height: 100% !important;
            object-fit: cover;
         }
      }
   }

   .welcome-title {
      z-index: 1;
      position: relative;
      margin: 60px auto;
      text-align: center;
      max-width: 600px;
      h1 {
         color: white;
         font-weight: bold;
         font-size: calc(18px + 2vw);
         line-height: calc(20px + 2.2vw);
         margin: 20px;
      }
      @media (min-width: 1060px) {
         margin: 0;
         text-align: center;
         h1 {
            font-size: 50px;
            line-height: 55px;
         }
      }
   }
   .auth-container {
      z-index: 1;
      .ant-collapse > .ant-collapse-item > .ant-collapse-header,
      .ant-collapse-ghost
         > .ant-collapse-item
         > .ant-collapse-content
         > .ant-collapse-content-box {
         padding-left: 0;
         padding-right: 0;
      }
   }
}
