@import "../../../colors.scss";
@import "../style.module.scss";

.userTitles {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;

   .userRole {
      padding-top: 40px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .row {
         width: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         border-bottom: 1px solid $lineGray;
         margin-bottom: 20px;

         &:last-child {
            border-bottom: none;
         }

         .roleTitle {
            margin-top: 15px;
            width: 80%;
         }
      }
   }

   .bottomButtons {
      @extend .bottomButtons;
   }
}

.organizationItem {
   @extend .organizationItem;
}
