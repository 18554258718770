@import "../../../globals";
.building-operations-overview-wrapper {
   .page-content {
      padding: 0 20px;
      h3 {
         letter-spacing: 1px;
         text-transform: uppercase;
         font-size: 12px;
      }
   }
}
.operation-list {
   margin-bottom: 40px;
}
.operation-item {
   display: flex;
   flex-flow: row nowrap;
   border: 2px solid #e2e2e2;
   border-radius: 20px;
   // justify-content: space-between;
   padding: 15px 20px;
   margin-bottom: 10px;
   > * {
      padding-right: 10px;
      display: block;
   }
   .operation-type {
      display: flex;
      flex-flow: column nowrap;
      width: 150px;
      text-align: left;
      width: auto;
      * {
         color: black;
      }
      color: black;
      strong {
         letter-spacing: -0.038rem;
         display: block;

         font-size: 24px;
         margin-bottom: -3px;
      }
      min-width: 227px;
   }

   .icon {
      border: 1px solid black;
      padding: 2px;
      width: 21px;
      height: 21px;
      margin-right: 5px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
   }
   .operation-timeline {
      width: 310px;
      margin-left: auto;
      padding-top: 5px;
      font-size: small;
      display: flex;
      flex-flow: row wrap;
      strong {
         padding-right: 5px;
         width: 100%;
      }

      .end-timeline {
         padding-bottom: 5px;
         &:before {
            content: " ";
            border-bottom: 1px solid black;
            width: 50px;
            height: 5px;
            display: inline-block;
            margin: 0 10px;
            transform: translateY(-5px);
         }
      }
   }
   .operation-results {
      padding-right: 0;
      margin-left: auto;
      width: 170px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
   }
   .actions {
      display: flex;
      flex-flow: row nowrap;
   }
   .action-tag {
      font-size: 0.7rem;
      margin-right: 10px;
      background-color: #eee;
      padding: 5px 10px;
      border-radius: 100px;
   }

   .finvalue {
      font-size: 0.7rem;
      margin-right: 10px;
      background-color: #eee;
      padding: 5px 10px;
      border-radius: 100px;
      font-weight: bold;
   }
   .ecovalue {
      font-size: 0.7rem;
      color: $green;
      background-color: #eee;
      padding: 5px 10px;
      border-radius: 100px;
      font-weight: bold;
   }
}

.charts {
   display: flex;
   .chart-widget {
      width: 30%;
      height: 300px;
      margin: 0px 3.33% 50px 0;
      &.col-2 {
         width: 60%;
      }
   }
}

.ant-input-group {
   strong {
      width: 100%;
   }
   .ant-form-item {
      padding-right: 20px;
   }
}

.building-operations-overview-wrapper {
   .ant-tabs-nav-list {
      border-radius: 100px;
      border: 2px solid #e2e2e2;
   }
   .ant-tabs-nav {
      margin: 10px 0px 0px 0;
      &:before {
         display: none;
      }
      .ant-tabs-tab {
         padding: 0;
         + .ant-tabs-tab {
            margin: 0;
            .ant-tabs-tab-btn {
               margin-left: 2px;
            }
         }
         &.ant-tabs-tab-active .ant-tabs-tab-btn {
            border-color: $green;
            color: $green;
         }
      }
      .ant-tabs-tab-btn {
         font-size: 14px;
         font-weight: bold;
         border-radius: 100px;
         border: 2px solid transparent;
         padding: 5px 15px;
         font-weight: bold;
         margin: -2px;
         &:hover {
            color: black;
            border-color: black;
         }
      }
      .ant-tabs-ink-bar {
         display: none;
      }
   }

   .tab-pane-header {
      margin: 0px 20px 20px 20px;
      padding-bottom: 10px;
      //border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: flex-end;
      flex-flow: row wrap;
      justify-content: space-between;
      h3 {
         margin: 0;
         display: none;
         font-weight: bold;
      }
      button {
         margin: -50px 0 0 auto;
      }
   }
}
// matching action
.matching-action,
.operations-intro {
   border-radius: 20px;
   background-color: #d6ffef;
   padding: 20px;
   margin: 10px 20px;

   h4 {
      font-weight: bold;
      color: black !important;
      font-size: 16px !important;
      text-transform: none !important;
      letter-spacing: initial !important;
   }
   p {
      max-width: 500px;
   }
   &.running {
      background-color: #eee;
      box-shadow: initial;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      border-radius: 0;
      margin: 0;
      padding: 20px 30px;
      button {
         margin: 0;
      }
   }
}
.operations-intro {
   margin: 10px 0px 20px 0;
}
// matching
.matching-list.seller {
   padding: 0 20px;
   margin-bottom: 50px;
   .matching-item-seller {
      border-bottom: 1px solid #e2e2e2;
      background-color: #f8f8f8;
      border-radius: 0;
      margin-bottom: 0;
      border-radius: 0;
      &:first-child {
         border-top: 1px solid #e2e2e2;
      }
      .matching-state-seller {
         display: flex;
         flex-flow: row nowrap;
         align-items: center;
         padding: 0;
         font-weight: bold;
         .state {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 0;
            width: 100px;
            justify-content: center;
         }
         .item-info {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 10px;
            transition: all 0.3s;
            &:hover {
               cursor: pointer;
               transition: all 0.3s;
               background-color: #edeff1;
               border-radius: 12px;
            }
            figure {
               margin-bottom: 0;
               width: 66px;
               height: 66px;
               display: flex;
               background-color: #eeeeee;
               align-items: center;
               justify-content: center;
               border-radius: 10px;
               margin-right: 12px;
               img {
                  width: 100%;
                  height: 100%;
                  border-radius: 10px;
                  object-fit: cover;
                  object-position: center center;
               }
            }
            .title {
               strong {
                  font-size: 13px;
               }
               display: flex;
               flex-direction: column;
               .amount {
                  font-size: 12px;
               }
            }
         }
         .progress-state {
            display: flex;
            align-items: center;
            padding: 0 20px;
            .progress-bar {
               width: 200px;
            }
            .progress-info {
               margin-left: 40px;
               font-weight: 400;
               font-size: 12px;
               .reserved {
                  color: #99e8ca;
               }
               .ordered {
                  color: #27b47c;
               }
               .delivered {
                  color: #000;
               }
            }
         }
      }
      .inner {
         display: flex;
         flex-flow: row nowrap;
         align-items: stretch;
         width: 100%;
         padding: 0 10px 0 0;
         .shadow-button {
            background-color: #d6f6ea;
            border: none;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 20px;
            color: #27b47c;
            height: 28px;
            padding: 3px 15px;
            font-weight: 600;
         }
         .last-col {
            .actions {
               display: flex;
               .shadow-button {
                  margin: 0 5px;
               }
            }
            .more-button {
               color: #b8b8b8;
               margin-left: 5px;
            }
         }
      }
      .order-wrapper {
         background-color: #fff;
         box-shadow: 0px 3px 6px #00000029;
         margin-bottom: 10px;
         border-radius: 12px;
         align-items: center;
         font-weight: bold;
         font-size: 12px;
         .hover-state {
            display: flex;
            transition: all 0.3s;
            align-items: center;
            padding: 0 5px 0 15px;
            span {
               margin-right: 10px;
            }
            &:hover {
               transition: all 0.3s;
               background-color: #edeff1;
               border-radius: 12px;
            }
            cursor: pointer;
            .order-status-label {
               min-width: 115px;
            }
            .item-info {
               display: flex;
               flex-flow: row nowrap;
               align-items: center;
               padding: 10px 5px;
               figure {
                  margin-bottom: 0;
                  width: 66px;
                  height: 66px;
                  display: flex;
                  background-color: #eeeeee;
                  align-items: center;
                  justify-content: center;
                  border-radius: 10px;
                  margin-right: 12px;
                  img {
                     width: 100%;
                     height: 100%;
                     border-radius: 10px;
                     object-fit: cover;
                     object-position: center center;
                  }
               }
               .order-title {
                  strong {
                     font-size: 13px;
                  }
                  display: flex;
                  flex-direction: column;
               }
            }
         }

         .last-col {
            justify-content: end;
            display: flex;
            align-items: center;
            padding: 0 10px;
         }
         .payment-info {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 10px;
            position: relative;
            div {
               align-items: center;
            }
            .price-info {
               font-size: 12px;
            }
            .price {
               font-size: 14px;
               text-align: center;
               padding-right: 4px;
               font-weight: bolder;
            }
         }
      }
      &.in-progress {
         .inner:not(.request) .state {
            justify-content: center;

            .search-icon {
               display: none;
            }
            figure.demanded {
               width: 30px;
               height: 30px;
               img.icon {
                  height: 20px;
                  width: 25px;
               }
               margin-right: 20px;
               box-shadow: initial;
            }
            figure.match {
               display: block;
            }
         }
      }
      &.paused {
         .inner:not(.request) .state {
            justify-content: center;

            .pause-icon {
               font-size: 16px;
               color: #000;
               background-color: #e5e5e5;
               width: 30px;
               height: 30px;
               position: relative;
               border-radius: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
            }
            .search-icon {
               display: none;
            }
            figure.demanded {
               margin-left: 20px;
               position: relative;
            }
            figure.match {
               display: none;
            }
         }
      }
      &.searching {
         .inner:not(.request) .state {
            justify-content: center;

            .search-icon {
               width: 30px;
               height: 30px;
               position: relative;
               background-color: #d6f6ea;
               color: #28b47c;
               border-radius: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
               .anticon-play-circle {
                  font-size: 20px;
                  color: $green;
               }
               .anticon-loading {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  img,
                  svg {
                     width: 30px;
                     height: 30px;
                  }
               }
            }
            .pause-icon {
               display: none;
            }
            figure.demanded {
               margin-left: 20px;
               position: relative;
            }
            figure.match {
               display: none;
            }
         }
         &.has-matches {
            .inner:not(.request) figure.demanded {
               &:after {
                  content: " ";
                  //border: 2px solid white;
                  width: 20px;
                  height: 20px;
                  border-radius: 100%;
                  background-color: $green;
                  position: absolute;
                  top: -10px;
                  left: -10px;
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
               }
            }
         }

         .inner.request {
            background-color: #e2e2e2;
            margin-top: 2px;
            &:last-child {
               margin-bottom: 15px;
            }
         }
      }
   }
   .matches,
   .requests {
      margin-bottom: 20px;
      padding: 20px 0 20px 50px;
      .matching-item,
      .request-item {
         border-bottom: 1px solid #dddddd;
         padding: 20px 0;
      }
      h4 {
         font-weight: bold;
      }
   }
}

.status-tag {
   align-items: center;
   display: flex;
   justify-content: center;
   padding: 3px 15px !important;
   border-radius: 25px;
   font-weight: bold;
   height: 26px;
   width: fit-content;
   min-width: fit-content;
   @media print {
      -webkit-print-color-adjust: exact;
   }
   &.pink {
      background-color: #ffd1e5;
   }
   &.accepted {
      background-color: #d6f6ea;
   }
   &.requested {
      background-color: #ffde87;
   }
   &.ordered,
   &.green {
      background-color: #a7ffdc;
   }
   &.cancelled,
   &.closed {
      background-color: #c9d0d5;
   }
}

// new order list
.matching-list,
.matches {
   padding: 0 20px;
   border: none;
   background-color: #f8f8f8;
   margin-bottom: 50px;

   .matching-item.demand-item {
      border: none;
      border-top: 2px solid #e7e7e7;
      margin-bottom: 0;
      border-radius: 0 !important;
      &:last-child {
         border-bottom: 2px solid #e7e7e7;
      }
      .d-flex {
         display: flex;
      }
      .demand {
         .name {
            font-size: 14px;
         }
         font-size: 13px;
         font-weight: bold;
         display: flex;
         flex-flow: row nowrap;
         align-items: center;
         justify-content: space-between;
         padding: 20px 0;
         .bordered-tag {
            border: 2px solid #ebebeb;
            border-radius: 20px;
            padding: 2px 10px;
            margin-right: 10px;
         }
      }
      .shadow-button {
         background-color: #d6f6ea;
         border: none;
         box-shadow: 0px 3px 6px #00000029;
         border-radius: 20px;
         color: #27b47c;
         height: 28px;
         padding: 3px 15px;
         font-weight: 600;
         &.with-icon span:not(.ico) {
            padding-left: 20px;
         }
         .ico {
            width: 3px;
            height: 3px;
            background: #27b47c;
            top: 10px;
            border-radius: 7px;
            box-shadow: 0 0px 0px 5px #27b47c40;
            padding: 4px;
            position: absolute;
         }
         &.orange {
            color: #fff;
            background-color: #ffa200;
         }
      }
      .shadow-tag {
         background: #ffffff 0% 0% no-repeat padding-box;
         box-shadow: 0px 3px 6px #00000029;
         border-radius: 20px;
         padding: 4px 15px;
         margin-left: 10px;
         height: 28px;
         &.green {
            background-color: #d6f6ea;
            color: #27b47c;
         }
      }
      .order-wrapper {
         background-color: #fff;
         box-shadow: 0px 3px 6px #00000029;
         margin-bottom: 10px;
         border-radius: 12px;
         align-items: center;
         font-weight: bold;
         font-size: 12px;
         .hover-state {
            display: flex;
            transition: all 0.3s;
            align-items: center;
            padding: 0 5px 0 15px;
            span {
               margin-right: 10px;
            }
            &:hover {
               transition: all 0.3s;
               background-color: #edeff1;
               border-radius: 12px;
            }
            cursor: pointer;
            .order-status-label {
               min-width: 115px;
            }
            .item-info {
               display: flex;
               flex-flow: row nowrap;
               align-items: center;
               padding: 10px 5px;
               figure {
                  margin-bottom: 0;
                  width: 66px;
                  height: 66px;
                  display: flex;
                  background-color: #eeeeee;
                  align-items: center;
                  justify-content: center;
                  border-radius: 10px;
                  margin-right: 12px;
                  img {
                     width: 100%;
                     height: 100%;
                     border-radius: 10px;
                     object-fit: cover;
                     object-position: center center;
                  }
               }
               .order-title {
                  strong {
                     font-size: 13px;
                  }
                  display: flex;
                  flex-direction: column;
               }
            }
         }

         .last-col {
            justify-content: end;
            display: flex;
            align-items: center;
            padding: 0 10px;
            .info-text {
               margin-right: 10px;
               font-size: 11px;
            }
            .more-button {
               color: #b8b8b8;
               margin-left: 5px;
            }
         }
         .payment-info {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 10px;
            position: relative;
            div {
               align-items: center;
            }
            .price-info {
               font-size: 12px;
            }
            .price {
               font-size: 14px;
               // min-width: 100px;
               text-align: center;
               padding-right: 4px;
               font-weight: bolder;
            }
         }
         .status {
            padding: 0;
            margin-left: 10px;
         }
         .self-deconst,
         .pickup {
            font-size: 10px;
            position: absolute;
            top: 31px;
            &.border {
               border-left: 3px solid #ffa200;
               padding-left: 5px;
            }
            span {
               padding-right: 5px;
               padding-left: 2px;
            }
         }
      }

      .date {
         width: 100px;
         display: flex;
         flex-flow: column nowrap;
         justify-content: center;
      }
      div.amount {
         width: 230px;
         padding-left: 20px;
         display: flex;
         flex-flow: column nowrap;
         justify-content: center;
      }
      // .item-name {
      //    display: flex;
      //    flex-flow: row nowrap;
      //    justify-content: center;

      //    padding: 10px 20px;
      //    min-width: 170px;
      //    max-width: auto;

      button.more-details {
         background: transparent;

         font-size: 0.8rem;
         color: black;
         padding: 0 !important;
         box-shadow: initial;
         margin: 10px auto 0 0;
         line-height: initial;
         height: auto;
         padding: 0;
         border: none;
         //max-width: 100px;
         text-shadow: initial;
         align-self: flex-start;
         text-align: left;
         span {
            text-decoration: underline;
         }
      }
      // }
      .evaluation {
         margin-left: auto;
         padding: 0 20px;
         width: 270px;
         display: flex;
         flex-flow: column wrap;
         justify-content: center;
         max-width: 270px;
         .tag {
            border-radius: 100px;
            margin-bottom: 10px;
            display: inline-block;
            font-size: 0.9em;
            font-weight: bold;
            &.sustainable {
               color: #52c41a;
            }
            &.cheap {
               color: red;
            }
         }
         dl {
            display: flex;
            flex-flow: row wrap;
            font-size: 0.9em;

            margin: 0;
            dt {
               width: 40%;
               margin-bottom: 0;
            }
            dd {
               width: 60%;
               font-weight: bold;
               margin-bottom: 0;
               &.eva-result {
                  color: green;
                  font-weight: bold;
               }
            }
         }
      }
      .target {
         min-width: 120px;
         padding: 10px;
         font-size: 0.8em;
         display: flex;
         flex-flow: column nowrap;
         justify-content: center;
         font-weight: bold;
         span {
            font-weight: bold;
         }
      }
      .offer {
         display: flex;
         flex-flow: column nowrap;
         justify-content: center;
      }

      .actions {
         display: flex;
         align-items: center;
         justify-content: flex-end;
         padding-left: 10px;
         padding-right: 20px;
         flex-flow: row nowrap;
         margin-left: auto;
         position: relative;
         .dotted-menu {
            position: absolute;
            top: 10px;
            right: 10px;
            .anticon-ellipsis {
               font-size: 20px;
               color: #999;
            }
         }
         button {
            border-radius: 100px;
            margin: 5px 0 0 10px;
         }
         .price {
            span {
               display: block;
               font-size: 0.8em;
               font-weight: normal;
            }
         }
      }
      &.in-progress {
         .inner:not(.request) .matching-state {
            .search-icon {
               display: none;
            }
            figure.demanded {
               width: 30px;
               height: 30px;
               img.icon {
                  height: 20px;
                  width: 25px;
               }
               margin-right: 20px;
               box-shadow: initial;
            }
            figure.match {
               display: block;
            }
         }
      }
      &.paused {
         .inner:not(.request) .matching-state {
            .pause-icon {
               width: 30px;
               height: 30px;
               position: relative;

               border-radius: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
               font-size: 20px;
               color: #ddd;
            }
            .search-icon {
               display: none;
            }
            figure.demanded {
               margin-left: 20px;
               position: relative;
            }
            figure.match {
               display: none;
            }
         }
      }
      &.searching {
         .inner:not(.request) .matching-state {
            .search-icon {
               width: 30px;
               height: 30px;
               position: relative;

               border-radius: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
               .anticon-play-circle {
                  font-size: 20px;
                  color: $green;
               }
               .anticon-loading {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  img,
                  svg {
                     width: 30px;
                     height: 30px;
                  }
               }
            }
            .pause-icon {
               display: none;
            }
            figure.demanded {
               margin-left: 20px;
               position: relative;
            }
            figure.match {
               display: none;
            }
         }
         &.has-matches {
            .inner:not(.request) figure.demanded {
               &:after {
                  content: " ";
                  //border: 2px solid white;
                  width: 20px;
                  height: 20px;
                  border-radius: 100%;
                  background-color: $green;
                  position: absolute;
                  top: -10px;
                  left: -10px;
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
               }
            }
         }
         .inner.request {
            background-color: #e2e2e2;
            margin-top: 2px;
            &:last-child {
               margin-bottom: 15px;
            }
         }
      }
   }
   .matches,
   .requests {
      margin-bottom: 20px;
      padding: 20px 0 20px 50px;
      .matching-item,
      .request-item {
         border-bottom: 1px solid #dddddd;
         padding: 20px 0;
      }
      h4 {
         font-weight: bold;
      }
   }
}

.more-menu {
   border-radius: 8px;
}

.matches.drawer {
   padding: 0;
   background-color: #fff;

   .matching-item {
      cursor: pointer;
      &:hover {
         border-color: black;
      }
      .evaluation {
         display: flex;
         .eva-result {
            display: none;
         }
      }
      .actions .link {
         font-size: 24px;
      }
   }
}
// material preview in drawer
.material-preview {
   .item-card {
      display: flex;
      flex-flow: row wrap;
      figure {
         box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
         margin-bottom: 0;
         width: 100px;
         height: 100px;
         display: flex;
         background-color: #eeeeee;
         align-items: center;
         justify-content: center;
         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;

            &.icon {
               width: 50px;
               height: 70px;
               object-fit: contain;
            }
         }
      }
      div {
         padding-left: 20px;
         display: flex;
         flex-flow: column nowrap;
         justify-content: center;
      }
   }
   dl {
      padding: 20px 0 10px 0;
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      dt {
         width: 40%;
         padding-bottom: 10px;
      }
      dd {
         width: 50%;
         padding-bottom: 10px;
      }
   }
}

.collapse.material-inventory-list {
   .ant-collapse {
      border-top: 1px solid #f6f6f6;
      .ant-collapse-content-box {
         padding: 0;
      }
      > .ant-collapse-item > .ant-collapse-header {
         padding: 5px 0;
      }
      .collapse-header {
         display: flex;
         align-items: center;
         flex-flow: row nowrap;
         padding: 10px 0;
         justify-content: space-between;
         figure {
            width: 50px;
            height: 50px;
            background-color: #eeeeee;
            border-radius: 10px;
            img {
               object-fit: cover;
               width: 100%;
               height: 100%;
               border-radius: 10px;
            }
         }
         .item-inner {
            padding-left: 10px;
            width: calc(100% - 60px);
            .item-title {
               margin-top: -6px;
               margin-bottom: -6px;
            }
         }
         strong {
            display: block;
         }
         .item-unit-select {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            align-items: center;
            .ant-tag {
               margin-left: 10px;
            }
            .actions {
               display: flex;
               flex-flow: row wrap;
               margin-left: auto;
               .link {
                  color: $green;
                  font-size: 12px;
                  &:hover {
                     text-decoration: underline;
                  }
               }
               span {
                  margin: 0 5px;
               }
               button {
                  padding: 0px 5px;
                  color: #000;
                  font-size: 14px;
                  font-weight: bold;
                  height: 35px;
                  min-width: 135px;
                  border-color: transparent;
                  box-shadow: 0px 3px 6px #00000029;
                  &:hover:not(.open) {
                     color: $green;
                  }
                  &.open {
                     background-color: #dfe2e5;
                     box-shadow: none;

                     &:hover {
                        box-shadow: none;
                     }
                  }
                  &.selected {
                     color: #27b47c;
                     background-color: #d6f6ea;
                     box-shadow: none;
                     span.anticon {
                        color: #27b47c;
                     }
                  }
                  span.anticon {
                     color: #707070;
                     font-size: 13px;
                  }
               }
            }
         }
      }
      .collapse-content {
         margin-bottom: 10px;

         .item-cluster {
            padding: 10px 10px 10px 0;
            border-bottom: 1px solid #eeeeee;
            &:last-child {
               border: none;
            }

            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .select-cluster-wrapper {
               background-color: #f1f1f1;
               padding: 5px 10px 9px;
               border-radius: 10px;
               flex: 2;

               &.on-sale {
                  background-color: #d6f6ea;
                  border-top: 3px solid #27b47c;
               }

               .select-cluster-title {
                  font-weight: bold;
                  margin-bottom: 2px;
                  font-size: 12px;
               }

               .select-cluster {
                  font-size: 12px;
                  display: flex;
                  flex-flow: row wrap;

                  .left-side {
                     display: flex;
                     flex-flow: row wrap;
                  }

                  .text-span {
                     padding: 0 8px;
                     font-size: 14px;
                  }

                  input {
                     font-weight: bold;
                     line-height: 46px;
                  }

                  .available {
                     padding-left: 5px;
                     display: block;
                     font-size: 10px;
                     padding-top: 1px;
                  }

                  button {
                     font-size: 12px;
                     margin-top: 6px;
                     margin-left: 10px;
                     border: none;
                     box-shadow: brown;
                     background-color: white;
                     box-shadow: 0px 0px 4px #c6c5c5;
                     color: #8d9192;

                     &:hover {
                        color: #59bd9a;
                     }

                     &.update-button {
                        background-color: #27b47c;
                        color: #fff;

                        span {
                           font-size: 18px;
                        }

                        &:hover {
                           color: #59bd9a;
                           background-color: #fff;
                        }
                     }
                  }
               }
            }

            .passport-specs {
               min-width: 100px;
               color: #8b8b8b;
               flex: 1;
               font-weight: bold;

               .availability {
                  font-size: 12px;
               }

               .pieces {
                  color: #000;
               }

               dl {
                  padding: 0;
                  margin: 5px 0;
                  display: flex;
                  flex-flow: row nowrap;
                  font-size: 10px;
                  line-height: 11px;
                  border-radius: 2px;
                  margin-right: 6px;

                  dt {
                     padding-right: 4px;
                     display: block;
                     margin: 0;
                     font-weight: bold;
                  }

                  dd {
                     padding: 0;
                     display: block;
                     margin: 0;
                  }
               }
            }
         }
      }
   }
}
.inventory-lookup {
   h2 {
      button {
         float: right;
      }
   }
}

.ant-drawer .cart-bar {
   position: absolute;
   bottom: -100px;
   transition: bottom 0.2s ease-in-out;
   &.show {
      bottom: 0;
   }
   left: 0;
   padding: 20px;
   z-index: 99;
   background-color: #ffffff;
   box-shadow: 0px 2px 10px #00000029;
   width: 100%;
   display: flex;
   align-items: center;
   .ant-btn-icon-only {
      &:hover {
         background-color: white;
      }
   }

   .actions {
      margin-left: auto;
   }

   .selected {
      background-color: #d6f6ea;
      border-radius: 41px;
      color: #27b47c;
      display: flex;
      flex-direction: row-reverse;
      line-height: 35px;
      padding: 0 5px 0 15px;
      align-items: center;
      button {
         width: 25px;
         height: 25px;
         border-radius: 13px;
         color: #707070;
         background-color: #e8faf3;
         display: flex;
         justify-content: center;
         align-items: center;
         .anticon {
            font-size: 12px;
         }
      }
      strong {
         color: #27b47c;
         margin-right: 25px;
      }
   }
}

.ant-drawer {
   .ant-spin-nested-loading,
   .ant-spin-container {
      position: inherit;
   }
   .material-overview-wrapper {
      padding-bottom: 115px;
      > * {
         padding-left: 0;
         padding-right: 0;
         margin-left: 0;
         margin-right: 0;
      }
      > *:first-child {
         padding-top: 0;
      }
      .images-scroll {
         margin-left: 0;
         width: calc(100% + 20px);
         margin-right: -20px;
         height: 224px;
         .ant-image {
            height: 224px;
            img {
               max-height: -webkit-fill-available;
            }
         }
      }
      .grid {
         margin-right: 0;
         width: 100%;
      }

      .self-deconstruction-date {
         margin-bottom: 18px;
         margin-top: 10px;
         font-size: 14px;
         font-weight: bold;
         span {
            color: $green;
            margin-right: 10px;
         }
      }
   }
}
.add-demand-form {
   max-width: 400px;
}

.request-status {
   border-radius: 15px;
   background-color: #e2e2e2;
   border: 2px solid #e2e2e2;
   margin-bottom: 20px;
   .sale-details {
      padding: 15px;
      background-color: white;
      border-radius: 13px;
      .order-volume {
         display: flex;
         flex-flow: row wrap;
         font-size: 120%;
         font-weight: bold;
         justify-content: space-between;
      }
   }
   .material-details {
      padding: 15px;
   }
   .tag {
      border: none;
      background-color: white;
      font-weight: bold;
      display: inline-block;
      margin: 0 10px 10px 0;
      padding: 6px 10px;
   }
}

.green-circle {
   width: 6px;
   height: 6px;
   background: #27b47c;
   border-radius: 50%;
   display: inline-block;
   margin-right: 6px;
}

.available-passport {
   width: 100%;
   background-color: #d6f6ea;
   border: none;
   border-top: 3px solid #27b47c;
   padding: 3px 10px 5px;
   border-radius: 9px;
   margin-bottom: 11px;

   .availability {
      display: flex;
      align-items: center;
      color: #70817a;
      font-weight: bold;
      font-size: 12px;
   }

   .passport-details {
      padding: 1px 0px 0px 13px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      &:last-child {
         border: none;
      }

      .passport-specs {
         flex: 1.5;
         color: #70817a;
         font-weight: bold;
         display: flex;

         .pieces {
            color: #000;
            font-weight: bold;
            font-size: 14px;
         }
      }

      .actions {
         flex: 3.4;
         display: flex;
         flex-flow: row wrap;
         align-items: center;

         input {
            height: 46px;
         }

         .text-span {
            padding: 0 8px;
            font-size: 13px;
            font-weight: 600;
         }

         button {
            background-color: white;
            color: $green;
            border: none;
            box-shadow: 0px 3px 6px #00000029;
            width: 90px;
            padding-left: 9px;
            &.disabled {
               background: #dfe2e5;
               color: #818587;
            }
            &.requested {
               background: $green;
               color: #fff;
               padding-left: 0;
               span {
                  font-size: 14px;
               }
            }
            span {
               font-size: 13px;
            }
         }

         .form-field {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
         }
      }

      dl {
         margin: 4px 0;
         display: flex;
         font-size: 10px;
         line-height: 11px;
         border-radius: 2px;
         margin-right: 6px;
         font-weight: bold;

         dd {
            padding: 0;
            display: block;
            margin: 0;
         }
         dt {
            padding-right: 4px;
            display: block;
            margin: 0;
            font-weight: bold;
         }
      }
   }
}

@media print {
   body #root {
      display: none !important;
   }
   .ant-modal-root {
      display: block !important;
      &::-webkit-scrollbar {
         width: 0 !important;
      }
      .ant-modal-wrap::-webkit-scrollbar {
         width: 0 !important;
      }
   }
}

@media screen {
   #printSection {
      display: none;
   }
}

@media print {
   body * {
      visibility: hidden;
   }
   #printSection,
   #printSection * {
      visibility: visible;
   }
   #printSection {
      position: absolute;
      left: 0;
      top: 0;
   }
}

.ant-modal-content {
   @media print {
      margin-top: -90px;
      box-shadow: none;
      &::-webkit-scrollbar {
         width: 0 !important;
      }
   }
   .ant-modal-body {
      @media print {
         padding: 0 !important;
      }
   }
   .ant-modal-close {
      @media print {
         display: none;
      }
   }
}

.requestform {
   padding: 5px 8px;

   .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      .title {
         font-size: 20px;
         font-weight: bold;
      }
      .buttons {
         padding-right: 15px;
         @media print {
            display: none;
         }
         button {
            margin: 0 10px;
            &.contact {
               box-shadow: 0px 3px 6px #012b2049;
               border-radius: 24px;
               color: #1900ff;
            }
         }
      }
   }
   .material-info {
      .material-image {
         width: 70px;
         height: 70px;
         img {
            border-radius: 12px;
         }
      }
      .products {
         padding-left: 20px;

         .title {
            font-weight: bold;
         }

         .cluster-row {
            border-bottom: 1px solid #dfe2e5;
            padding: 0 0 3px;
            margin-bottom: 10px;

            &:last-child {
               margin-bottom: 0;
               padding-bottom: 0;
               border-bottom: none;
            }

            .requested-passport {
               display: flex;
               justify-content: space-between;
               font-weight: bold;
               font-size: 12px;
               padding: 15px 15px 10px;

               .pieces {
                  justify-content: space-between;
                  .available-pieces {
                     span {
                        width: 60px;
                        display: inline-block;
                        text-align: center;
                     }

                     .price {
                        margin-right: 10px;
                     }
                  }

                  .selected-pieces {
                     display: flex;
                     .input {
                        margin-top: -13px;
                        margin-right: 10px;
                        input {
                           height: 45px;
                        }
                     }
                  }

                  .delete-btn {
                     cursor: pointer;
                     color: #818587;
                     margin-left: 10px;
                     width: 11px;
                     height: 11px;

                     &:disabled {
                        color: #dbdbdb;
                     }
                  }
               }

               .item-price {
                  &.justify-end {
                     justify-content: end;
                     .price {
                        min-width: auto;
                        height: 34px;
                     }
                  }
                  .price {
                     min-width: 100px;
                     display: inline-block;
                     text-align: center;
                  }
               }
            }
            .deconst-info {
               background-color: #eff1f3;
               padding: 10px 15px 10px;
               border-radius: 12px;
               font-weight: bold;
               min-height: 152px;
               margin-bottom: 10px;
               @media print {
                  -webkit-print-color-adjust: exact;
               }
               .ant-col {
                  @media print {
                     max-width: 50%;
                     flex: 1;
                  }
               }
               &.fix {
                  min-height: 100px;
               }

               .disabled {
                  pointer-events: none;
                  opacity: 0.4;
               }

               .sub-description {
                  font-size: 12px;
                  padding-top: 7px;
               }

               .self-deconst {
                  border-left: 3px solid #ffa200;
                  padding-top: 0;
                  padding-left: 10px;
               }
               .selected-option {
                  background-color: #dfe2e5;
                  border-radius: 100px;
                  font-size: 12px;
                  padding: 1px 10px;
                  min-width: 60px;
                  text-align: center;
               }
            }
         }
      }
   }

   .bold-title {
      margin-right: 10px;
      font-weight: bold;
      font-size: 13px;
   }

   .attention {
      color: #ffa200;
   }
   .subtitle {
      color: #818587;
      font-style: italic;
      font-weight: normal;

      &.mt-5 {
         margin-top: 5px;
         max-width: 236px;
      }
   }

   .pickup-address {
      margin-top: 5px;
      font-weight: bolder;
   }

   .total-price {
      border-top: 2px solid #000000;
      font-weight: bold;
      margin-top: 3px;
      padding-top: 10px;
      @media print {
         max-width: 50%;
         flex: 0 0 40%;
         margin-left: 60%;
      }

      div:first-child {
         justify-content: space-between;
      }

      .shipping-cost {
         text-align: right;
         &.price {
            margin-top: 10px;
            font-size: 13px;
         }
      }

      .sum-price {
         border-top: 2px solid #000;
         margin-top: 10px;
         padding-top: 10px;
      }

      .calculation-info {
         @extend .subtitle;
         text-align: right;
      }

      .payment-status {
         padding-top: 5px;
         display: flex;
         justify-content: end;
         .status-tag {
            @extend .status-tag;
         }
      }
   }

   .details {
      margin-top: 40px;
      .row {
         display: flex;
         justify-content: space-between;
         border-top: 1px solid #dfe2e5;
         padding-top: 10px;
         padding-bottom: 12px;
         align-items: center;

         .title {
            font-weight: bold;
            padding-right: 20px;
         }

         .address {
            font-weight: bold;
            font-size: 13px;
            padding-top: 1px;
         }

         button {
            min-width: 70px;
         }

         .edit,
         .close {
            background-color: #dfe2e5;
            color: #000000;
            border: none;
         }
      }

      .info-inputs {
         padding-bottom: 30px;

         .label {
            color: #b7b7b7;
            padding-top: 10px;
            padding-bottom: 1px;
         }

         .input {
            margin-right: 15px;
            max-width: 215px;
         }

         &.disabled {
            display: none;
         }
      }
      .no-arrow {
         input::-webkit-outer-spin-button,
         input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
         }

         /* Firefox */
         input[type="number"] {
            -moz-appearance: textfield;
         }
      }
   }

   .validation-warning {
      color: red;
      margin-right: 20px;
      margin-top: 7px;

      span {
         margin-left: 6px;
      }
   }
}

.display-flex {
   display: flex;

   &.space-between {
      justify-content: space-between;
   }
}
