.ant-drawer-content-wrapper,
.ant-drawer-content {
   border-top-left-radius: 50px;
   //border-bottom-left-radius: 50px;
}

.ant-drawer-content-wrapper {
   @media (max-width: 600px) {
      width: 100% !important;
   }
}
.ant-drawer-content {
   padding: 0px 0 0px 20px;
   overflow: hidden;
}
.ant-drawer-body {
   padding: 60px 20px 0 0;
}
.ant-drawer-body h2 {
   font-weight: bold;
   letter-spacing: -0.038rem;
}
.ant-drawer .ant-drawer-close {
   left: 7px;
   top: 7px;
   right: auto;
   padding: 0;
   height: 50px;
   width: 50px;
   background-color: white;
   border-radius: 100%;
   content: "<-";
   font-family: "Inter", "system-ui";
   font-feature-settings: "ss01" on, "tnum" off, "kern" on, "calt" on;
   &:after {
      content: "<-";
      font-family: "Inter", "system-ui";
      font-feature-settings: "ss01" on, "tnum" off, "kern" on, "calt" on;
      font-size: 24px;
   }
   * {
      display: none;
   }
   &:hover {
      background-color: #e2e2e2;
   }
}
