@import "../../colors";

.upload-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   width: 100%;
   background: $backgroundGray;
   border-radius: 5px;
   padding: 1em;
   & .label {
      display: block;
      font-size: 20px;
      font-weight: bold;
   }
   & .hint {
      display: block;
      margin: 0.3em auto 0.7em;
      font-size: 12px;
      font-weight: bold;
      color: gray;
   }
   & input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
   }
   & label {
      background: $white;
      padding: 0.5em;
      border-radius: 5px;
      width: 100%;
      cursor: pointer;
   }
}

.file-list-item {
   display: flex-row;
   align-items: center;
   margin: 1em 0em;
   padding: 0.4em 0.8em;
   box-shadow: 0.7px 0.7px 3px rgba($color: #888, $alpha: 0.5);
   border-radius: 5px;
}
