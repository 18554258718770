.subscription {
   min-height: 100vh;
   padding-top: 40px;
   .goBack {
      padding-left: 30px;
   }
   .title {
      @media screen and (max-width: 990px) {
         text-align: center;
         margin-top: 20px;
      }
   }
   .mainWrapper {
      .plans {
         margin-top: 40px;
         @media screen and (max-width: 576px) {
            margin-top: 30px;
            margin-bottom: 30px;
         }
         @media screen and (max-width: 990px) {
            margin-left: 15px;
            margin-right: 15px;
         }
         .plan {
            box-shadow: 0px 3px 6px #00000029;
            padding: 28px;
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            margin-right: 8px;
            margin-left: 8px;
            @media screen and (max-width: 576px) {
               margin-bottom: 24px;
               height: auto;
               margin-right: 0;
               margin-left: 0;
            }
            .name {
               font-weight: bold;
               font-size: 22px;
               padding-left: 8px;
            }
            .description {
               margin: 15px 0;
            }
            .price {
               font-size: 30px;
               font-weight: bold;
            }
            .recurring {
               font-weight: bold;
            }
            .optionsList {
               padding-left: 0;
               list-style: none;
               font-weight: 700;
               margin: 5px 0 20px;
               span {
                  &:first-child {
                     margin-right: 10px;
                     font-weight: 800;
                     font-size: 16px;
                  }
               }
            }
         }
      }
   }
}
