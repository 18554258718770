@import "../../../colors.scss";
@import "../style.module.scss";

.welcome {
   height: 90vh;
   @media screen and (max-width: 1600px) {
      height: 90vh;
   }
   .wrapper {
      @extend .wrapper;
      height: 598px;
      @media screen and (max-width: 1600px) {
         height: 80%;
      }
      .message {
         font-weight: bold;
         font-size: 50px;
         text-align: center;
         padding-top: 30px;
         padding-bottom: 40px;
         min-height: 132px;
         @media screen and (max-width: 1600px) {
            font-size: 40px;
         }
      }
   }
}
