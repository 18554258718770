@import "../../../colors.scss";

.building-list-overview {
   padding: 20px 50px;
   @media screen and (max-width: 768px) {
      padding: 0;
      .inner-wrap {
         padding: 0 25px;
      }
   }
   .ant-page-header .ant-page-header-content {
      border: none;
   }
   .content-wrap {
      background-color: white;
   }
   .page-title {
      margin-bottom: 20px;
      // border-bottom: 1px solid $borderGray;
      .big-title {
         font-size: 40px;
         font-weight: bold;
         @media screen and (max-width: 1600px) {
            font-size: 30px;
         }
      }
   }
}

.noBuilding {
   background-color: $backgroundGray;
   color: $definiteGray;
   border-radius: 12px;
   padding: 50px 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   margin-bottom: 20px;
   .title {
      color: $definiteGray;
      margin-top: 15px;
      font-weight: bold;
   }
}

.org-no-building {
   background: $backgroundGray;
   height: 120px;
   margin-right: 12px;
   margin-bottom: 20px;
   justify-content: space-between;
   align-items: center;
   display: flex;
   cursor: pointer;
   color: $definiteGray;
   border-radius: 8px;
   width: 200px;
   height: 200px;
   flex-flow: column nowrap;
   .header {
      width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 55px;
      img {
         width: 30px;
      }
      .title {
         font-size: 13px;
         margin-top: 15px;
         font-weight: bold;
         color: $definiteGray;
      }
   }

   .desc {
      border-top: 1px solid $lineGray;
      height: 50px;
      font-size: 10px;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px 0px;
      span:first-child {
         margin-right: 3px;
      }
   }
}
.building-list {
   align-items: stretch;
   flex-flow: column nowrap;
   @media screen and (max-width: 768px) {
      padding: 10px 25px;
   }
   @media (min-width: 600px) {
      flex-flow: row wrap;
   }
   .org-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $borderGray;
      margin-bottom: 20px;
      padding-bottom: 15px;
      &:last-child {
         border-bottom: none;
      }
      .buildings {
         margin-top: 15px;
         display: flex;
         flex-flow: wrap;
      }
   }
}

.intro-buildings {
   padding: 10px 20px;
   border-radius: 20px;
   background-color: #d6ffef;
   margin-bottom: 20px;
   font-weight: bold;
   display: inline-block;
}
