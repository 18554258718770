.material-inventory-overview-wrapper {
   padding: 0px;
}

.view-header.materialhub {
   padding: 30px;
}

.steps-container {
   padding-top: 30px;
   padding-right: 20px;
   padding-bottom: 20px;
   min-height: 70vh;
}
