@import "../../../colors.scss";

.mention {
   width: 100%;
   margin-bottom: 10px;
   .label {
      color: $definiteGray;
   }
   .inputValues {
      min-height: 34px;
      height: auto;
      border-radius: 5px;
      border: 2px solid $borderGray;
      transition: all 0.3s;
      padding: 3px;
      display: flex;
      flex-wrap: wrap;
      &:hover {
         border: 2px solid $hoverGreen;
         transition: all 0.3s;
      }
      .input {
         width: auto;
      }
   }

   .tag {
      margin-bottom: 2px;
      background-color: $backgroundGray;
      border: none;
      border-radius: 4px;
      height: 27px;
      line-height: 27px;
   }
}

.error {
   color: $red;
   position: absolute;
   margin-top: -2px;
}
