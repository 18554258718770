@import "~antd/dist/antd.less";

:root {
   --amplify-primary-color: #1da57a;
   --amplify-primary-tint: #1da57a;
   --amplify-primary-shade: #1da57a;
}

amplify-authenticator {
   & .auth-container {
      min-height: 100px !important;
   }
}

.ant-form-item-label {
   padding: 0px !important;
}

@primary-color: #1DA57A;