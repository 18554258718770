.building-files-view {
   .file-upload-container {
      width: 200px;
      height: 100%;
      margin-bottom: 25px;
      margin-left: 12px;
      margin-right: 12px;
      .file-upload-button {
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         cursor: pointer;
         padding: 10px;
         font-size: 0.8rem;
         .ant-upload-text,
         .ant-upload-hint {
            font-size: 0.8rem;
         }
      }
   }
   .ant-card {
      margin-right: 12px;
      margin-left: 12px;
   }
   .file-upload-view-container {
      margin-right: 15px;
      margin-bottom: 25px;
      width: 200px;
      height: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #11806014;
      border: 2px solid #11806096;
   }
   .ant-card-body {
      padding: 15px 25px;
   }
}
.delete-file-button {
   margin-top: -17px;
   position: absolute;
   top: 28px;
   right: 13px;
}

.download-btn {
   position: absolute;
   right: 58px;
   top: 11px;
   background: white;
   border: 1px solid green;

   &:hover {
      background: white;
   }
}
