// @import "./globals";
@import "./colors";

.amplify-button[data-variation="primary"] {
   background: $green;
   color: white;

   &:hover {
      background: $green;
      opacity: 0.8;
   }
}

.amplify-tabs-item {
   color: black;
   &[data-state="active"] {
      color: $green;
      border-color: $green;
   }
}

.amplify-button[data-size="small"] {
   color: $green;
   &:hover {
      background: transparent;
      color: $green;
      text-decoration: underline;
   }
}

.amplify-input {
   color: black;
}

.amplify-select:focus,
.amplify-input:focus,
.amplify-textarea:focus {
   border: 1px solid $green;
   outline: none;
   box-shadow: none;
}

.ant-form-item-label > label {
   white-space: normal !important;
}
