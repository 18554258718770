.material-inventory-overview-wrapper {
   padding: 0px;
   height: 100%;
   display: flex;
   flex-direction: column;
}

.steps-container {
   padding-top: 30px;
   padding-right: 20px;
   padding-bottom: 20px;
   min-height: 70vh;
}
