@import "../../../colors.scss";

.addBuilding {
   .addForm {
      margin-top: 10px;
      @media screen and (max-width: 768px) {
         margin-top: 0;
         padding: 10px 20px;
      }
   }
   .backButton {
      @media screen and (min-width: 769px) {
         margin-top: 30px;
         margin-left: 30px;
      }
   }
   .description {
      margin-top: 15px;
      margin-bottom: 20px;
   }
   .stateState {
      margin-bottom: 20px;
   }
   .formStyles {
      .label {
         color: $definiteGray;
         align-items: center;
         display: flex;
         @media screen and (min-width: 769px) {
            padding-bottom: 24px;
         }
         @media screen and (max-width: 768px) {
            padding-bottom: 4px;
         }
         &.yearOfContruction {
            @media screen and (min-width: 769px) {
               padding-bottom: 15px !important;
            }
            @media screen and (max-width: 768px) {
               padding-bottom: 4px !important;
            }
         }
         &.plannedErrection {
            @media screen and (max-width: 768px) {
               padding-bottom: 4px !important;
            }
         }
      }
      .topLabel {
         @extend .label;
         @media screen and (min-width: 769px) {
            margin-bottom: 5px;
         }
      }
      .section {
         margin: 10px 0;
         .row {
            border-bottom: 1px solid $backgroundGray;
            padding-bottom: 10px;
            padding-top: 5px;
            &:last-child {
               border-bottom: none;
               margin-top: 5px;
            }
            .label {
               padding-bottom: 0;
               align-items: flex-start;
               @media screen and (min-width: 769px) {
                  padding-top: 10px;
               }
               @media screen and (max-width: 768px) {
                  padding-bottom: 4px;
               }
            }
         }
      }

      .sectionTitle {
         font-size: 24px;
         font-weight: bold;
         margin-bottom: 20px;
         @media screen and (max-width: 1600px) {
            font-size: 17px;
            margin-bottom: 15px;
         }
      }
   }
}
