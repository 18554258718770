// colors
$green: #27b47c;
$hoverGreen: #38c58d;
$lightGreen: #d6f6ea;

$orange: #ffa200;
$lightYellow: #fff0d6;

$white: #fff;
$black: #000000;
$hoverBlack: #585858;
//gray
$definiteGray: #818587;
$borderGray: #c9d0d5;
$lineGray: #dfe2e5;
$backgroundGray: #ececec;
$lightGray: #f8f8f8;

$red: #ff4d4f;
$lightRed: #ffdfeb;

$blue: #1900ff;
