.message.noaccess {
   padding: 20px;
   background-color: #ffd900;
   color: black;
   font-weight: 600;
   text-align: center;
}
.unauthorized-teaser {
   background-color: #f6f6f6;
   min-height: calc(100vh - 66px);

   .teaser-body {
      padding: 20px;
      h2 {
         font-size: 24px;
      }
      .feature-grid {
         display: flex;
         flex-flow: row wrap;
         align-items: stretch;
         justify-content: space-between;
         &:after {
            content: " ";
            flex: auto;
         }
         .grid-item {
            background-color: #ffffff;
            width: calc(25% - 20px);
            min-width: 250px;
            padding: 20px;
            border-radius: 5px;
            border: 1px solid #eeeeee;
            margin-bottom: 20px;
            margin-right: 20px;
            h3 {
               font-weight: bold;
               font-size: 1.2rem;
            }
            &:hover {
               border-color: #dddddd;
            }
            display: flex;
            flex-flow: column nowrap;
            .inner {
               flex-grow: 1;
               padding-bottom: 10px;
               + * {
                  margin-right: auto;
               }
            }
            &.feedback {
               border: 1px solid #ffd900;
            }
            .comingsoon {
               display: inline-block;
               color: #666666;
               background-color: #eeeeee;
               border-radius: 3px;
               font-size: 0.65rem;
               padding: 2px 5px;
            }
         }
      }
   }
}
