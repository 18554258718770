.material-inventory-wizard-wrapper {
   & .steps-wrapper {
      max-width: 700px;
      margin-top: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      .ant-steps-item-title {
         font-size: 0.7rem;
      }
   }

   & .ant-steps-item-icon {
      display: none;
   }

   & .wizard-title {
      font-weight: 700;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 25px;
      font-size: 2rem;
   }
}

.ant-steps-item-active {
   font-weight: bold;
}
@media (max-width: 600px) {
   .ant-steps-vertical > .ant-steps-item-wait {
      display: none;
   }
   .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
      min-height: 0;
   }
}

.steps-wrapper-content {
   margin-bottom: 20px;
}

button.ant-btn-default {
   margin-top: 10px;
}

.form-elements-wrapper {
   width: 100%;
   @media (min-width: 600px) {
      width: 50%;
      padding-right: 20px;
   }
}
