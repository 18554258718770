.material-catalogue-wrapper.page-wrap {
   h1 {
      color: white;
   }
   .inventory-content {
      padding: 20px 0px 20px 20px;
      .reset {
         span {
            color: white;
         }
         &:hover span {
            color: black;
         }
      }
      .ant-tree-list-holder-inner {
         color: white;
         .ant-tree-treenode:hover {
            color: black;
         }
      }
   }
   .inventory-content
      .category-sidebar
      .category-list
      .ant-tree.ant-tree-directory
      .ant-tree-treenode-selected:hover::before,
   .inventory-content
      .category-sidebar
      .category-list
      .ant-tree.ant-tree-directory
      .ant-tree-treenode-selected::before {
      color: black;
      background-color: white;
   }
   .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher,
   .inventory-content
      .category-sidebar
      .category-list
      .ant-tree.ant-tree-directory
      .ant-tree-treenode-selected
      .ant-tree-node-content-wrapper {
      color: black !important;
   }
}

.material-tiles {
   display: flex;
   align-items: stretch;
   justify-content: space-between;
   flex-wrap: wrap;
   &:after {
      content: " ";
      flex: auto;
   }
}
.material-tile-item {
   width: 100%;
   border-radius: 20px;
   margin-right: 20px;
   background-color: white;
   //border-bottom: 2px solid #ddd;
   margin-bottom: 20px;
   transition: all 0.2s ease-in-out;
   cursor: pointer;

   height: 500px;
   position: relative;
   @media (min-width: 800px) {
      width: calc(50% - 20px);
   }
   @media (min-width: 1100px) {
      width: calc(33.33% - 20px);
   }
   @media (min-width: 1500px) {
      width: calc(25% - 20px);
   }
   &:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
      //border-color: black;
      .ant-image img {
         //object-fit: contain;
      }
   }

   img {
      object-fit: cover;
      //background-color: #f6f6f6;
      object-position: 50% 50%;
      height: 100% !important;
      width: 100% !important;
      border-radius: 20px;
      //transition: all 0.2s ease-in-out;
   }
   .ant-image {
      width: 100% !important;
      height: 100% !important;
      //height: 300px !important;
      margin-bottom: 10px;
      position: absolute;
      border-radius: 20px;
      top: 0;
      left: 0;
   }
   .item-content {
      position: relative;
      z-index: 1;
      color: white;
      position: absolute;
      bottom: 0;
      padding: 30px 10px 10px 10px;
      background: rgb(0, 0, 0);
      background: -moz-linear-gradient(
         0deg,
         rgba(0, 0, 0, 1) 0%,
         rgba(0, 0, 0, 0) 100%
      );
      background: -webkit-linear-gradient(
         0deg,
         rgba(0, 0, 0, 1) 0%,
         rgba(0, 0, 0, 0) 100%
      );
      background: linear-gradient(
         0deg,
         rgba(0, 0, 0, 1) 0%,
         rgba(0, 0, 0, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
      width: 100%;
   }
   .item-title {
      font-weight: bold;
      font-size: 0.7rem;
      span {
         display: block;
         color: rgba(255, 255, 255, 0.5);
         letter-spacing: 1px;
         font-size: 0.45rem;
         text-transform: uppercase;
         font-weight: normal;
         padding-top: 2px;
      }
   }
   .inner-row {
      display: flex;
      flex-flow: row nowrap;
      font-size: 0.7rem;
      margin-top: 10px;
      align-items: center;
   }
   .amount {
      flex-grow: 0;
      padding-right: 10px;
   }
   .separator-line {
      flex-grow: 1;
      border-top: 0.01rem solid rgba(255, 255, 255, 0.5);
      height: 1;
   }
   .availability {
      flex-grow: 0;
      padding-left: 10px;
   }
}

.material-specifications {
   dd.color {
      color: transparent;
      &:before {
         width: 60px;
         height: 20px;
         background-color: var(--background);
         content: " ";
         display: inline-block;
         border: 1px solid black;
      }
   }
}

.requestform {
   .product {
      display: flex;
      figure {
         margin-right: 15px;
      }
   }
}

.load-more {
   text-align: center;
}
