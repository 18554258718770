@import "../../globals";
.action-header-wrapper {
   display: flex;
   justify-content: flex-end;
   align-items: end;
   flex-direction: row;
   margin-bottom: 25px;
   & .ant-select {
      margin-right: 15px;
   }
}

.building-image-carousel {
   width: 400px;
}

// sidebar

// .page-sidebar {
//    + main {
//       border-top-left-radius: 0;
//       border-top-right-radius: 20px;
//       @media (min-width: 769px) {
//          border-top-left-radius: 0;
//          border-top-right-radius: 50px;
//       }
//    }
//    background-color: #e2e2e2;
//    border-top-left-radius: 50px;
//    @media (min-width: 769px) {
//       //border-right: 1px solid #f6f6f6;
//       //height: calc(100vh - 50px);
//       flex: 0 0 250px !important;
//       max-width: 250px !important;
//       min-width: 250px !important;
//       width: 250px !important;
//       //position: sticky;
//       //top: 50px;
//       margin-top: -2px;
//    }

//    .ant-layout-sider-children {
//       display: flex;
//       flex-flow: column nowrap;
//       justify-items: stretch;
//       align-content: stretch;

//       @media (min-width: 769px) {
//          position: fixed;
//          width: 250px;
//          top: 70px;
//       }
//       div.menus {
//          display: flex;
//          flex-flow: row nowrap;
//          overflow-x: auto;
//          overflow-y: hidden;
//          @media (min-width: 769px) {
//             display: flex;
//             flex-flow: column nowrap;
//             justify-items: stretch;
//             align-content: stretch;
//             height: 100%;
//          }
//       }
//       .main {
//          margin-bottom: auto;
//       }
//       .secondary {
//          margin-top: auto;
//       }
//    }

//    .view-header {
//       display: flex;
//       flex-flow: row nowrap;
//       align-items: center;
//       padding-left: 0;
//       .view-title {
//          margin: 0;
//          padding: 30px 10px 20px 30px;
//          font-size: 14px;
//          font-weight: 800;
//          letter-spacing: -0.038em;
//       }
//       img {
//          width: 35px;
//          height: 35px;
//          display: block;
//          object-fit: cover;
//          margin-left: 15px;
//          border-radius: 5px;
//          display: none;
//       }
//    }

//    ul.ant-menu {
//       width: calc(100% - 20px);
//       border-right: none;
//       background: none;
//       margin-left: -2px;
//       li.ant-menu-item {
//          margin: 0 !important;
//          padding-left: 20px;
//          border-right: none;
//          border-left: 0px solid transparent;
//          border-top-right-radius: 100px;
//          border-bottom-right-radius: 100px;
//          height: 35px;
//          line-height: 36px;
//          position: relative;
//          &:before {
//             position: absolute;
//             top: 0;
//             bottom: 0;
//             left: 0;
//             width: 5px;
//             content: " ";
//             border-top-right-radius: 10px;
//             border-bottom-right-radius: 10px;
//          }
//          a,
//          .ant-menu-title-content {
//             border-right: none;
//             font-size: 17px;
//             letter-spacing: -0.038em;
//             font-weight: bold;
//             &:hover {
//                color: black;
//             }
//          }
//          span.anticon {
//             width: 25px;
//             margin-right: 15px;
//             color: black;
//             display: none;
//          }

//          &.ant-menu-item-active {
//             background-color: transparent;
//             &:before {
//                background-color: rgba(0, 0, 0, 0.2);
//             }
//             a,
//             .ant-menu-title-content {
//                color: black;
//                font-size: 17px;
//             }
//          }
//          &.ant-menu-item-selected {
//             &:before {
//                background-color: $green;
//             }
//             background-color: white;

//             box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

//             a {
//                font-weight: bold;
//                color: black;
//                font-size: 17px;
//                span,
//                svg,
//                .anticon {
//                   color: black;
//                }
//             }
//          }
//       }
//    }
// }
// page layout
.building-overview-wrapper {
   // page content
   .page-content-container {
      padding: 20px 20px;
   }

   .separated {
      border-top: 2px solid black;
      padding-top: 20px;
      margin: 0 20px;
   }

   .page-content {
      padding: 0px 20px 20px 20px;
      @media (min-width: 1024px) {
         display: flex;
         flex-flow: row nowrap;
      }
      h3 {
         display: block;
         font-size: 0.7rem;
         flex-grow: 1;
         text-transform: uppercase;
         letter-spacing: 1px;
         color: #999;
         margin-bottom: 15px;
      }
      address {
         font-style: normal;
         font-weight: bold;
      }
      .description {
         font-size: 17px;
         line-height: 20px;
         font-weight: bold;
      }

      .activity-log {
         background-color: white;
         width: 100%;
         @media (min-width: 1200px) {
            flex: 0 0 calc(50% - 20px);
            width: calc(50% - 20px);
            margin-right: 20px;
         }
         padding: 10px 0;

         margin-bottom: 20px;
         .chain {
            margin-left: 10px;
            .chain-item {
               padding-left: 20px;
               position: relative;
               padding-bottom: 20px;
               border-left: 1px solid black;
               &:last-child {
                  border: none;
               }
               &:before {
                  content: " ";
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  top: 0;
                  left: -10px;
                  background-color: black;
               }
               &.upcoming {
                  &:before {
                     border: 2px solid black;
                     background-color: white;
                  }
               }
               strong,
               span {
                  display: block;
               }
            }
         }
      }
   }
}
