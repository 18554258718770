@import "../../../colors.scss";
@import "../style.module.scss";

.joinOranization {
   @extend .onboarding;
   padding-top: 40px;
   @media screen and (max-width: 1600px) {
      padding-top: 20px;
   }
   .organizationRow {
      @extend .organizationRow;
      .organizationItem {
         @extend .organizationItem;
      }
      .status {
         color: #d1d2d3;
         padding-right: 10px;
      }
   }
   .description {
      color: #818587;
      text-align: center;
      padding: 20px;
      position: relative;
      width: 80%;
      &::after {
         content: "";
         background: #dfe2e5;
         width: 30%;
         position: absolute;
         left: 50%;
         height: 1px;
         transform: translateX(-50%);
         bottom: 0;
      }
   }
   .bottomButtons {
      margin-top: 30px;
   }
   .createNew {
      text-align: center;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
   }
   .skip {
      @extend .linkButton;
      direction: rtl;
      margin-top: 30px;
      span {
         padding-right: 5px;
      }
   }

   &.modalView {
      padding: 0 20px;
      .backButton span {
         font-size: 12px;
         font-weight: bold;
      }
      .modalTitle {
         display: flex;
         align-items: center;
         margin-bottom: 20px;
         h3 {
            font-weight: bold;
            margin-bottom: 0;
            margin-left: 12px;
         }
      }
      .userTitlePage {
         width: 100%;
         min-width: 450px;
      }
   }
}

.joinComponent {
   justify-content: center;
   align-items: center;
   display: flex;
   flex-direction: column;
   width: 450px;
}
