.ant-upload-list-text {
   max-width: 200px;
}

.ant-space-align-baseline {
   align-items: center;
}

.ant-space-item {
   span.anticon.anticon-minus-circle {
      margin-bottom: 28px;
   }
}

.form-wrap.material-inventory-wizard-wrapper {
   .ant-collapse-item {
      margin-bottom: 30px;
      &.ant-collapse-item-active {
         .ant-collapse-header {
            max-height: 1px;
            padding-top: 0;
            padding-bottom: 0;
         }
      }
   }
   .ant-collapse-header {
      background-color: #eeeeee;
      margin: 20px 0;
      overflow: hidden;
   }
   .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
   }
}
