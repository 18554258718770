.passport-id {
   display: inline-block;
   font-weight: bold;
   font-size: 0.6rem;
   padding: 2px 5px;
   background-color: #5c68d9;
   color: white;
   border-radius: 2px;
   clear: both;
   .tag {
      background-color: rgba(255, 255, 255, 0.2);
      color: white;
      display: inline-block;
      border: none;
      float: right;
      height: auto;
      padding: 2px 10px;
      font-size: 0.6rem;
      margin: -2px 0 0 5px;
   }
}
