@import "../../../colors.scss";

.dropdownField {
   .label {
      color: $definiteGray;
      margin-bottom: 5px;
   }
   .middle {
      .ant-select-selector {
         height: 34px !important;
      }
   }
}
