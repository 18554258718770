@import "./../../../colors.scss";

.tag {
   padding: 0 10px;
   border-radius: 15px;
   display: flex;
   justify-content: center;
   width: auto;
   max-width: fit-content;
   align-items: center;
   font-weight: bold;
   &.success {
      color: $green;
      background-color: $lightGreen;
   }
   &.attention {
      color: $orange;
      background-color: $lightYellow;
   }
   &.warning {
      color: $red;
      background-color: $lightRed;
   }
   &.small {
      height: 23px;
      font-size: 11px;
   }
   &.middle {
      min-width: 66px;
      height: 30px;
   }
}
