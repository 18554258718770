.public-demand-page {
   .welcome-box {
      z-index: 1;
      position: relative;
      .welcome-box-inner,
      .section {
         --width: 100%;
         --min-width: 0;
         --max-width: 600px;
         --padding: 35px 40px;
         --margin-bottom: 20px;
         --container-display: flex;
         --container-justify: center;
         --container-align: center;
         width: 100%;
         min-width: 0;
         max-width: 600px;
         padding: 35px 40px;
         margin-bottom: 20px;
         container-display: flex;
         container-justify: center;
         container-align: center;
         height: auto;
         --container-height: auto !important;
         background-color: white;
         border-radius: 10px;
         border: none;
         input,
         .ant-form-item-control {
            width: 100%;
            max-width: 100%;
         }
         button {
            width: 100%;
            max-width: 100%;
            display: block;
            margin-bottom: 0px;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            touch-action: manipulation;
            cursor: pointer;
            user-select: none;
            background-image: none;
            line-height: 14px;
            color: white;
            height: auto;
            padding: 16px;
            letter-spacing: 0.75px;
            text-transform: uppercase;
            background-color: rgb(29, 165, 122);
            border-width: 0;
         }
      }
      > *:first-child {
         height: auto;
         --container-height: auto !important;
      }
   }
}

.public-demand {
   .amplify-tabs {
      display: none;
   }
}
