@import "./../Onboarding/style.module.scss";
@import "../../colors.scss";

.settings {
   @media screen and (min-width: 769) {
      display: table !important;
      min-height: 100vh;
   }

   width: 100%;
   .sideBar {
      z-index: 1;
      vertical-align: top;
      @media screen and (min-width: 769px) {
         display: table-cell;
         width: 300px;
         min-height: calc(100vh - 50px);
         padding: 20px 0 80px 13px;
         background-color: $lightGray;
      }
      @media screen and (max-width: 768px) {
         padding: 20px 15px 13px;
         position: inherit;
      }
      .pageTitle {
         font-weight: bold;
         @media screen and (min-width: 769px) {
            padding-left: 10px;
         }
         @media screen and (max-width: 768px) {
            display: none;
         }
      }
      .organizationSettings {
         &:last-child {
            margin-bottom: 10px;
         }
      }
      .collapse {
         a {
            display: block;
            color: $black;
            padding: 5px 15px 5px 25px;
            margin-bottom: 2px;
            border-radius: 50px 0 0 50px;
            &.selected {
               background-color: $backgroundGray;
            }
            font-weight: 600;
            &:active {
               background-color: $backgroundGray;
            }
         }
      }
      .createOrJoin {
         position: fixed;
         bottom: 30px;
         border-radius: 14px;
         left: 120px;
         z-index: 1;
         @media screen and (max-width: 1600px) {
            left: 90px;
         }
         @media screen and (max-width: 768px) {
            left: 20px;
            bottom: 20px;
         }
      }
      .organizationsDropdown {
         :global(.ant-select-selector) {
            height: 50px !important;
            padding: 0px 10px !important;
            cursor: pointer !important;
         }
         width: 100%;
      }

      .menu {
         border-right: none;
         background: none;
         @media (max-width: 768px) {
            margin-left: 0;
            margin-top: 5px;
         }
         .menuItem:global(.ant-menu-item) {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            line-height: 40px;
            position: relative;
            display: flex;
            align-items: center;
            color: $black;
            padding: 0 8px;
            &:hover,
            &:focus {
               color: $black;
            }
            a {
               color: $black;
               font-weight: bold;
               padding: 11px 0 !important;
               border-radius: 0;
               background-color: transparent;
               margin-bottom: 0;
               &.selected {
                  border-bottom: 2px solid $green;
               }
               &:hover,
               &:focus {
                  color: $black;
               }
            }
            &::after {
               display: none;
            }
            &:before {
               position: absolute;
               top: 0;
               bottom: 0;
               left: 0;
               width: 5px;
               content: " ";
               border-top-right-radius: 10px;
               border-bottom-right-radius: 10px;
            }
         }
      }
   }

   .content {
      @media screen and (min-width: 769px) {
         display: table-cell;
         padding: 30px 40px;
      }

      vertical-align: top;
      width: 100%;

      @media screen and (max-width: 768px) {
         padding: 0 20px 60px;
      }
      .contentTitle {
         font-size: 40px;
         margin-bottom: 35px;
         font-weight: bold;
         @media screen and (max-width: 1600px) {
            font-size: 30px;
            margin-bottom: 30px;
         }
         @media screen and (max-width: 768px) {
            display: none;
         }
      }
      .row {
         border-bottom: 1px solid $backgroundGray;
         padding: 10px 0;
         @media screen and (max-width: 768px) {
            border-bottom: none;
         }
         .label {
            color: $definiteGray;
            align-items: center;
            display: flex;
            @media screen and (max-width: 768px) {
               padding-bottom: 4px;
            }
         }
      }
      .submitForm {
         display: flex;
         justify-content: end;
         margin-top: 30px;
      }
      .accountForm {
         &.readOnly {
            pointer-events: none;
         }
         .zipCity {
            display: flex;
            .zip {
               width: 90px;
               margin-right: 5px;
            }
            .city {
               width: 80%;
            }
         }

         .ust {
            width: 100%;
         }
      }
      .section {
         margin-bottom: 20px;
      }
      .invitationSettings {
         border-bottom: 1px solid $backgroundGray;
         padding-bottom: 15px;
         .description {
            color: $definiteGray;
            max-width: 350px;
         }
         .saveChanges {
            margin: -10px 0 5px;
            display: flex;
            justify-content: flex-end;
         }
      }
      .memberSettings {
         padding-top: 10px;

         .members {
            .titleRow {
               color: $definiteGray;
               padding: 12px 0;
               font-size: 15px;
            }
            .userRow {
               border-bottom: 1px solid $backgroundGray;
               font-weight: 600;
               color: $definiteGray;
               padding: 10px 0;
               height: 53px;
               line-height: 32px;
               font-size: 14px;
               @media screen and (max-width: 1600px) {
                  font-size: 13px;
               }
               &:last-child {
                  border-bottom: none;
               }
               &.active {
                  color: $black;
                  .status {
                     background-color: $lightGreen;
                     color: $green;
                  }
               }
               .statusCol {
                  display: flex;
                  height: fit-content;
                  align-items: center;
               }
               .status {
                  color: $orange;
                  background-color: $lightYellow;
                  padding: 0 10px;
                  border-radius: 15px;
                  min-width: 66px;
                  display: flex;
                  justify-content: center;
                  width: auto;
                  max-width: fit-content;
                  height: 30px;
                  align-items: center;
               }
               .resend {
                  color: $black;
               }
               .joinedDate {
                  padding-left: 8px;
                  color: $definiteGray;
                  font-size: 11px;
                  font-weight: normal;
               }
               .userTypeSelect {
                  width: 120px;
               }
               .buttons {
                  display: flex;
                  justify-content: end;
                  .linkButton {
                     padding: 5px;
                  }
                  .normalButton {
                     margin-left: 4px;
                  }
               }
            }
            .informationRoles {
               margin-top: 40px;
               background-color: $backgroundGray;
               .ant-collapse-item {
                  border-bottom: none !important;
               }
               strong {
                  display: block;
                  margin-top: 15px;
               }
               ul {
                  margin: 0;
                  padding: 0;
                  list-style: none;
                  li {
                     margin: 0;
                     padding: 5px 0;
                     .anticon {
                        margin-right: 10px;
                     }
                  }
               }
               p {
                  margin-top: 20px;
               }
            }
         }
      }
      .fieldsTitle {
         font-size: 24px;
         font-weight: bold;
         @media screen and (max-width: 1600px) {
            font-size: 17px;
            margin-bottom: 15px;
         }
      }
      .plansAndBilling {
         margin-bottom: 40px;
         .tabContent {
            margin-top: 20px;
         }
         .row {
            border-bottom: none;
            padding: 5px 0;
            height: 30px !important;
         }
         .section {
            .headerTitle {
               margin-bottom: 8px;
               font-size: 18px;
               font-weight: bold;
            }
            .box {
               background: $white;
               box-shadow: 0px 3px 6px #00000029;
               border-radius: 12px;
               padding: 10px 17px;
               font-weight: bold;
               margin-bottom: 20px;
               min-height: 102px;
               margin-left: 5px;
               .boxTitle {
                  align-items: center;
                  margin-bottom: 12px;
                  height: 35px;
                  display: flex;
                  .title {
                     font-size: 15px;
                     font-weight: bold;
                     margin-bottom: 0;
                  }
                  .icon {
                     width: 18px;
                     margin-right: 8px;
                  }
               }
               .row {
                  height: 37px;
                  display: flex;
                  font-size: 13px;
                  font-weight: bold;
                  align-items: center;
                  justify-content: space-between;
               }
               .subTitle {
                  color: $definiteGray;
                  font-size: 11px;
               }
               // .tag {
               //    background-color: $lineGray;
               //    padding: 4px 10px;
               //    border-radius: 18px;
               //    font-weight: normal;
               // }
               &.paymentMethod {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  .tags {
                     display: flex;
                     width: 90%;
                     justify-content: flex-end;
                     span {
                        margin-left: 8px;
                     }
                  }
               }
               .card {
                  display: flex;
                  justify-content: space-between;
                  padding: 15px 0 0 0;
                  align-items: center;
                  .cardLogo {
                     width: 40px;
                     object-fit: contain;
                  }
               }
            }
         }
         .addNewCard {
            background-color: $backgroundGray;
            min-height: 102px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            color: $definiteGray;
            justify-content: center;
            margin-bottom: 20px;
            padding: 18px;
            cursor: pointer;
            .addIcon {
               font-size: 30px;
               margin-right: 15px;
            }
         }
         .subscriptions {
            .section {
               .header {
                  align-items: center;
                  margin-bottom: 12px;
                  height: 35px;
                  display: flex;
                  .title {
                     font-size: 15px;
                     font-weight: bold;
                     margin-bottom: 0;
                  }
                  .icon {
                     width: 18px;
                     margin-right: 8px;
                  }
               }
            }
         }
      }
      .userSettings {
         .password {
            border: none !important;
            background-color: $white;
            pointer-events: none;
            width: 50%;
         }
         .changePassword {
            color: $green;
         }
         .section {
            margin-top: 30px;
         }
      }
      .confirm {
         background-color: rebeccapurple;
      }
      .orgUserSettings {
         .title {
            width: 100%;
            margin-right: 10px;
         }
      }
   }
}

.inviteUser {
   padding: 10px 20px;
   .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
      @media screen and (max-width: 1600px) {
         font-size: 20px;
      }
   }
   .email {
      width: 100%;
   }
   .userTypeSelect {
      width: 200px;
      margin-left: 10px;
   }
   .sendButton {
      margin-top: 30px;
   }
}
