.container {
   width: 100%;
   height: 100%;
}

.ifc-container {
   display: flex;
   flex-direction: column;
   width: 100%;

   &__file-input {
      min-width: 70%;
   }

   &-content {
      display: flex;
      width: 100%;
      height: 100%;
   }

   &-list-wrapper {
      display: flex;
      width: 100%;

      > div {
         width: 50%;

         &:nth-child(2) {
            max-height: calc(100vh - 300px);
            overflow-y: scroll;
         }
      }
   }
}
