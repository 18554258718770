@import "../../../colors.scss";

.inputField {
   width: 100%;
   .label {
      color: $definiteGray;
      margin-bottom: 5px;
   }
   .icon {
      position: absolute;
      left: 11px;
      top: -1px;
      color: $definiteGray;
   }
   .withIcon {
      padding-left: 30px;
   }
   .simpleInput {
      position: relative;
   }
}
