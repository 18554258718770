@import "../../../colors";
// inventory layout
.inventory-content {
   display: flex;
   flex-flow: column nowrap;
   @media (min-width: 768px) {
      flex-flow: row nowrap;
      padding: 20px;
   }
   .category-sidebar {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid black;

      @media (min-width: 768px) {
         flex: 0 0 200px;
         border: none;
         margin-bottom: 0;
         padding-bottom: 0;
         margin-right: 20px;
         width: 200px;
      }
      .category-sidebar-title {
         text-transform: uppercase;
         letter-spacing: 1px;
         color: #999;
         font-size: 0.7rem;
      }
      .reset {
         margin: 5px 0 5px 0;
         border-radius: 100px;
         &:hover {
            background-color: #f6f6f6;
         }
         span {
            padding: 5px 10px;
            font-weight: bold;
            font-size: 0.8rem;
            display: block;
         }
         span.ant-radio {
            display: none;
         }
      }
      .category-list {
         margin-left: -10px;
         width: calc(100% + 20px);
         @media (min-width: 768px) {
            position: sticky;
            top: 50px;
            max-height: calc(100vh - 50px);
            overflow: auto;
         }
         .ant-tree.ant-tree-directory .ant-tree-treenode {
            padding-left: 10px;
            padding-bottom: 0;
            display: flex;
            align-items: flex-start;
            .ant-tree-indent-unit {
               width: 20px;
            }
            .ant-tree-node-content-wrapper {
               padding: 5px 5px 5px 0;
               font-weight: bold;

               font-size: 17px;
               line-height: 1.1rem;
               letter-spacing: -0.038em;
            }
            .ant-tree-switcher {
               display: flex;
               align-items: center;
               width: 20px;
               max-height: 26px;
            }
         }
         .ant-tree.ant-tree-directory .ant-tree-treenode::before {
            border-radius: 100px;
            bottom: 0;
         }
         .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
         .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
            background-color: black;
         }
      }
   }
   .material-list {
      overflow: hidden;

      @media (min-width: 768px) {
         width: calc(100% - 220px);
      }
      .ant-col:nth-child(2) {
         padding-left: 0 !important;
      }

      .material-item-header {
         text-transform: uppercase;
         font-size: 0.6rem;
         letter-spacing: 1px;
         padding-bottom: 10px;
         border-bottom: 1px solid #eeeeee;
         margin: 0 0 10px 0 !important;
      }
   }

   .material-grids {
      overflow: hidden;

      @media (min-width: 768px) {
         width: 100%;
      }

      .link-item {
         font-weight: 500;
         cursor: pointer;
         color: #000;
      }

      .edit-item {
         padding-top: 3px;
         cursor: pointer;
      }
   }
   .ant-tabs-tab .anticon {
      margin-right: 5px;
   }
}

.material-item-header,
.material-row-item {
   display: flex;
   flex-flow: row nowrap;
   > div,
   > span {
      padding-left: 10px;
      display: block;
   }
   .item {
      width: 250px;
   }
   .amount {
      width: 150px;
   }
   .location {
      width: 150px;
   }
   .sell {
      width: 150px;
      margin-left: auto;
      display: none;
   }
   .menu {
      width: 30px;
   }
}
// material row item
.material-row-item {
   font-weight: 600;
   background-color: white;
   margin: 0 0 10px 0 !important;
   width: 100%;
   cursor: pointer;
   padding: 10px 0;
   border-bottom: 2px solid #eeeeee;
   .item {
      display: flex;
      flex-flow: row nowrap;
      flex-grow: 1;
      .ant-image {
         flex-shrink: 0;
      }
      img {
         object-fit: cover;
         background-color: #f6f6f6;
         object-position: 50% 50%;
         border-radius: 20px;
      }
      .item-title {
         padding-left: 10px;
         color: #000;
         strong {
            font-weight: bold;
            display: block;
            padding-bottom: 10px;
         }
      }
   }
   &:hover {
      background-color: #f5f5f5;
   }

   .product-group-label {
      font-size: 10px;
      display: block;
      color: #9e9d9d;
   }
   .passport-amount {
      padding: 5px 10px;
      color: white;
      background-color: #5c68d9;
      display: inline-block;
      border-radius: 3px;
   }
   .sell {
      display: none;
      .sell-title {
         display: none;
      }
      .available-until {
         margin-top: 10px;
         display: flex;
         flex-flow: row nowrap;
         align-items: center;
         > span {
            font-size: 0.6rem;
            line-height: 0.6rem;
            color: #999999;
            display: block;
            margin-right: 5px;
         }
         div {
            padding: 4px 8px;
            background-color: #f6f6f6;
            border-radius: 100px;
            white-space: nowrap;
            font-size: 0.7rem;
            line-height: 0.7rem;
            span {
               display: inline-block;
            }
         }
      }
   }
   @media (max-width: 600px) {
      flex-flow: column nowrap;
      .item {
         .ant-image {
            width: 80px !important;
            height: 80px !important;
         }
         img {
            width: 80px !important;
            height: 80px !important;
         }
      }
      .sell {
         margin-left: 0;
         margin-top: 15px;
         width: 100%;
         display: none;
         .sell-title {
            display: inline-block;
            text-transform: uppercase;
            font-size: 0.6rem;
            letter-spacing: 1px;
            color: #999;
            + button {
               float: right;
            }
         }
      }
      .menu {
         position: absolute;
         top: 5px;
         right: 0;
         width: 30px;
      }
   }
   @media (min-width: 767px) and (max-width: 1024px) {
      .item {
         .ant-image {
            width: 80px !important;
            height: 80px !important;
         }
         img {
            width: 80px !important;
            height: 80px !important;
         }
      }
   }
   /*
  @media (min-width: 768px) and (max-width: 1024px) {
    flex-flow: column nowrap;
    position: relative;
    width: calc(50% - 20px);
    margin-right: 20px;
    padding-top: 0;
    .item {
      flex-flow: column nowrap;
      padding-left: 0;
      width: 100%;
      .ant-image {
        width: 100% !important;
        height: 200px !important;
        margin-bottom: 15px;
      }
      img {
        width: 100%;
        height: 100% !important;
      }
    }
    .sell {
      margin-left: 0;
      margin-top: 15px;
      .sell-title {
        display: block;
      }
    }
    
    .menu {
      position: absolute;
      top: 5px;
      right: 0;
      width: 30px;
    }
  }*/
}

.passport-images-carousel {
   width: 300px;
}

.ant-switch-inner {
   font-size: 0.6rem;
   letter-spacing: 1px;
   white-space: nowrap;
}

.grid-view .material-overview-wrapper {
   @media screen and (min-width: 768px) {
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0px 1px 8px #00000029;
      margin: 5px 20px;
   }
}

// material detail
.material-overview-wrapper,
.passport-overview-wrapper {
   padding: 20px 0;
   @media screen and (max-width: 768px) {
      padding: 0;
   }
   .head-elements {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .externalFilters {
         display: flex;
         .categoryFilter,
         .productGroupFilter {
            min-width: 200px;
            margin-left: 20px;
            @media screen and (max-width: 768px) {
               display: none;
            }
         }
      }
      .export-excel {
         @media screen and (max-width: 768px) {
            display: none;
         }
      }
      .search-product {
         @media screen and (max-width: 768px) {
            margin-bottom: 20px;
         }
      }
   }
   .ant-page-header .ant-page-header-content {
      padding-bottom: 20px;
   }
   // page content
   .separated {
      border-top: 2px solid black;
      padding-top: 20px;
      margin: 0 20px;
   }
   .page-content {
      display: flex;
      flex-flow: column nowrap;
      padding-left: 20px;
      padding-right: 20px;

      address {
         font-style: normal;
         font-weight: bold;
      }
      .description {
         font-size: 17px;
         line-height: 20px;
         font-weight: bold;
      }

      .material-specifications {
         flex-flow: column nowrap;
         @media (min-width: 600px) {
            flex-flow: row wrap;
         }
         .images {
            margin-right: 20px;
            width: 100%;
            background-color: #f6f6f6;
            padding: 0;
            .ant-carousel {
               display: flex;
               align-items: stretch;
               height: 100%;
            }
            .slick-list,
            .slick-slider {
               width: 100% !important;
               height: 100%;
            }
            .slick-track {
               height: 100%;
            }
            figure,
            .slick-slide div {
               width: 100%;
               height: 70vw;

               img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                  object-position: 50% 50%;
               }
            }
            @media (min-width: 1200px) {
               width: calc(25% - 20px);
               figure,
               .slick-slide div {
                  height: 100%;
                  min-height: 300px;
               }
            }
         }
         .specs {
            width: 100%;

            @media (min-width: 1024px) {
               margin-right: 20px;
               max-width: 700px;
               width: calc(50% - 20px);
            }
            dl {
               margin-bottom: 10px;
               display: flex;
               align-items: stretch;
               flex-flow: row wrap;
               border-bottom: 1px solid #f6f6f6;
               + h4 {
                  padding-top: 10px;
               }
               dd {
                  width: calc(100% - 150px);
                  min-width: 100px;
                  font-weight: bold;
                  margin: 0;
                  padding-bottom: 4px;
               }
               dt {
                  width: 150px;
                  padding-right: 20px;
                  padding-bottom: 4px;
               }
               &:not(.material) {
                  dt {
                     padding-top: 2px;
                     font-size: 0.7rem;
                  }
               }
            }
         }
         .description {
            width: 100%;
            @media (min-width: 1024px) {
               width: calc(50% - 20px);
            }
            @media (min-width: 1200px) {
               width: calc(25% - 20px);
            }
         }
      }
   }
}

.ant-drawer .material-overview-wrapper,
.ant-drawer .passport-overview-wrapper {
   .page-content {
      .material-specifications {
         flex-flow: column nowrap;
         .description {
            width: 100% !important;
         }
         .widget {
            width: 100% !important;
            margin: 0 0 0px 0;
         }
      }
   }
}

.passport-list-header {
   padding: 0 20px 0px 20px;
   display: flex;
   flex-flow: row wrap;

   h2 {
      flex-grow: 1;
      padding-right: 20px;
      margin: 10px 0;
   }
   p {
      margin: 10px 0;
      font-weight: bold;
      button {
         margin-left: 10px;
      }
   }
}
.passport-list {
   padding: 0 0 0 20px;
   display: flex;
   flex-flow: row wrap;
   align-items: stretch;
}
.content-wrap.passport-overview-wrapper {
   .ant-page-header .ant-page-header-content {
      justify-content: flex-start;
      background-color: #4f33ff;
      position: static;
      color: white;
      padding: 20px;
      margin-left: -20px;
      width: calc(100% + 40px);
      flex-flow: column nowrap;
      @media (min-width: 600px) {
         flex-flow: row wrap;
      }
      .page-title {
         max-width: initial;
      }
      h1 {
         color: white;
      }
      .passport-id {
         background-color: transparent;
         padding: 0 0 5px 0;
         font-size: 1rem;
      }
      .qr-code {
         width: 100%;
         margin-right: 20px;
         padding: 10px;
         border-radius: 10px;
         background-color: white;
         margin-bottom: 20px;
         img {
            width: 100%;
         }
         @media (min-width: 600px) {
            width: 140px;
            img {
               width: 120px;
            }
         }
      }
      .manage-menu {
         margin-left: auto;
         position: absolute;
         top: 30px;
         right: 20px;
         @media (min-width: 769px) {
            top: 50px;
         }
      }
   }
   .passport-subhead {
      background-color: #4f33ff;
      color: white;
      padding: 20px 0 20px 20px;

      width: 100%;
      border-top: 2px solid rgba(255, 255, 255, 0.5);
      .grid {
         display: flex;
         flex-flow: row wrap;
         .grid-item {
            width: 100%;
            padding-right: 20px;
            @media (min-width: 600px) {
               width: 33.333%;
               flex-shrink: 1;
               &:first-child {
                  flex-grow: 1;
                  min-width: 300px;
               }
            }
            h3 {
               color: white;
               font-weight: 600;
            }
            p {
               color: white;
            }
            dl {
               display: flex;
               flex-flow: row wrap;
               font-size: 0.8rem;
               dt {
                  width: 50%;
                  padding-right: 10px;
               }
               dd {
                  width: 50%;
               }
            }
            .tag {
               padding: 6px 15px;
               height: auto;
               font-weight: bold;
               display: inline-block;
               background-color: rgba(255, 255, 255, 0.3);
               border: none;
               color: white;
            }
         }
      }
   }
}

.ant-drawer .passport-item {
   min-width: 100%;
}
// passport item
.passport-item {
   //border-bottom: 1px solid #0059ac;
   border-radius: 20px;
   background-color: #e2e2e2;
   border: 2px solid #e2e2e2;
   cursor: pointer;
   margin-bottom: 20px;
   margin-right: 20px;
   padding-bottom: 10px;
   display: flex;
   font-size: 0.8rem;
   flex-flow: column nowrap;
   transition: box-shadow 0.2s ease-in-out;
   width: 100%;
   min-width: 300px;
   @media (min-width: 700px) {
      width: calc(50% - 20px);
   }
   @media (min-width: 1200px) {
      width: calc(33.333% - 20px);
   }
   &:hover {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
   }
   figure {
      margin: 0;
      width: 100%;
      height: 120px !important;
      background: #eee;
      overflow-x: auto;
      overflow-y: hidden;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      .ant-image {
         width: auto !important;
         height: 120px !important;
      }
      img {
         width: auto;
         height: 120px !important;
      }
   }
   .passport-details {
      padding: 10px;
      h3 {
         font-weight: bold;
      }
      dl {
         margin-top: 15px;
         margin-bottom: 0;
         display: flex;
         align-items: stretch;
         flex-flow: row wrap;
         dd {
            width: calc(100% - 140px);
            min-width: 100px;
            font-weight: bold;
            margin: 0;
            padding-bottom: 4px;
         }
         dt {
            width: 140px;
            padding-right: 20px;
            padding-bottom: 4px;
            padding-top: 2px;
            font-size: 0.7rem;
         }
      }
      div.form-field {
         margin-top: 30px;
         border-bottom: 1px solid #ddd;
         padding-bottom: 10px;
         display: flex;
         flex-flow: row wrap;
         align-items: center;
         .ant-input-number {
            width: 100px;
            margin-right: 10px;
         }
         .ant-input-affix-wrapper {
            width: 120px;
            margin-right: 10px;
            margin-left: 10px;
         }
         .ant-input {
            width: 100px;
            margin-right: 10px;
         }
         .button,
         .ant-btn {
            margin-left: 10px;
         }
      }
      div.actions {
         margin-top: 30px;
         border-bottom: 1px solid #ddd;
         padding-bottom: 10px;
         display: flex;
         flex-flow: row wrap;
         align-items: center;
         b {
            padding-right: 10px;
            font-size: 14px;
            flex-grow: 1;
         }
         @media (max-width: 600px) {
            button {
               width: 100%;
               display: block;
            }
            button.ant-btn-primary {
               width: 100%;
               height: 50px;
               display: block;
               margin: 10px 0;
            }
         }
      }
   }
   .passport-availability-details {
      padding: 0 10px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      span {
         display: block;
      }
      .availability {
         color: $green;
         font-weight: bold;
         display: block;
         padding-bottom: 10px;
      }
      .requested {
         padding: 10px;
         background-color: white;
         border-radius: 10px;
      }
   }
   .passports {
      .passport-id {
         display: block;
         margin: 5px 0 0 0;
         padding: 10px;
         border-radius: 3px;
      }
      .ant-collapse-header {
         padding: 5px 10px;
      }
      .ant-collapse-content {
         max-height: 400px;
      }
      .ant-collapse-ghost
         > .ant-collapse-item
         > .ant-collapse-content
         > .ant-collapse-content-box {
         padding-left: 10px;
         padding-right: 10px;
         padding-bottom: 0;
      }
      .ant-collapse-content-active {
         max-height: 400px;
         overflow: auto;
      }
   }
   /*.passport-price-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  
    & span {
      display: block;
    }
  }*/
}

.amount-unit {
   position: absolute;
   top: 84px;
   left: 290px;
}

/*
// new style
.catalogue-style {
  &.material-overview-wrapper {
    .ant-page-header-content {
      border: none;
    }
    .widget {
      &.data {
        min-height: 0;
      }
      border-bottom: none;
      > span:first-child {
        height: auto;
        padding-bottom: 5px;
      }
      strong {
        font-weight: 600;
      }
    }
    
  }
}
*/

.inventory-operation-intro {
   border-radius: 100px;
   background-color: #fff9c3;
   padding: 10px 20px;
   margin: 0 0 10px 0;

   h4 {
      color: black !important;
      font-size: 14px !important;
      text-transform: none !important;
      letter-spacing: initial !important;
      margin: 0;
      b {
         font-weight: bold;
      }
   }
}

.product-info-mobile {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   font-size: 11px;
   padding-left: 10px;
   span {
      &:first-child {
         height: 15px;
         margin-top: -10px;
         font-weight: bold;
      }
   }
}

.dotted-menu {
   @media screen and (max-width: 768px) {
      margin-right: 15px;
   }
   .icon {
      color: $definiteGray;
      font-size: 23px;
   }
}

.custom-row-styles {
   // padding: 10px 0 !important;
   border: none;
}

.inventory {
   &.ag-theme-alpine {
      font-family: inherit;
      .ag-header {
         border-bottom-color: $backgroundGray;
         background-color: #fff;
         .ag-header-cell {
            color: $definiteGray;
         }
      }
      .ag-root-wrapper {
         border: none !important;
      }
      .ag-side-bar {
         position: absolute;
         top: 0;
         right: 0;
         background: transparent;
         &.ag-side-bar-right {
            border-left: none;
            .ag-tool-panel-wrapper {
               border-right: none;
            }
         }
         .ag-side-buttons {
            width: 50px;
            padding-top: 0;
            button.ag-side-button-button {
               min-height: 45px;
               border-left: none;
               background: #fff;
               padding-top: 15px;
               padding-right: 7px;
               padding-bottom: 8px;
            }
         }
      }
      .ag-tool-panel-wrapper {
         height: 600px;
      }
      .ag-column-select-virtual-list-viewport {
         border: 1px solid $borderGray;
      }
      .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
         border: none;
      }
      .ag-pinned-right-header {
         border: none;
      }
      .ag-virtual-list-container {
         .ag-virtual-list-item:last-child {
            display: none;
         }
      }
      .ag-cell-focus {
         border: none !important;
      }
      .ag-center-cols-container {
         @media screen and (max-width: 768px) {
            width: 100% !important;
         }
      }
      .ag-cell {
         @media screen and (max-width: 768px) {
            display: flex !important;
            align-items: center;
         }
      }
   }
   .ag-pinned-right-header .ag-header-row::after {
      @media screen and (max-width: 768px) {
         display: none;
      }
   }
   .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
      border-left: solid 0px;
   }
}
